<template>
  <div>
    <!-- for RadioGroup, elementui use component tag to define this component -->
    <!-- so use this method to solve this problem -->
    <!-- refer: https://github.com/ElemeFE/element/blob/dev/packages/radio/src/radio-group.vue -->
    <div v-if="componentTag === `RADIOGROUP`">
      <el-radio-group
        v-model="currentModel"
        v-bind="config.component"
        v-on="config.event"
      >
        <!-- TODO: el-radio & el-radio-button setting -->
        <el-radio-button
          v-for="option in config.options"
          :key="option.id"
          :label="option.value"
        >
          {{ option.label }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <component
      :is="config.type"
      v-else
      v-model="currentModel"
      v-bind="config.component"
      style="width: 100%;"
      v-on="config.event"
    >
      <template v-if="componentTag === `SELECT`">
        <i
          v-if="isRemoteSelect"
          slot="prefix"
          class="el-input__icon el-icon-search"
        />
        <el-option
          v-for="option in config.options"
          :key="option.id"
          :value="option.value"
          :label="option.label"
        />
      </template>
      <template v-else-if="componentTag === `CHECKBOXGROUP`">
        <el-checkbox
          v-for="option in config.options"
          :key="option.id"
          :label="option.value"
        >
          {{ option.label }}
        </el-checkbox>
      </template>
    </component>
  </div>
</template>
<script>
export default {
  name: 'SimpleFormElement',
  props: {
    config: { type: Object, required: true, default: _ => { } },
    model: { type: [String, Number, Boolean, Object, Array] }
  },
  computed: {
    currentModel: {
      get () { return this.model },
      set (val) { this.$emit('update:model', val) }
    },

    // select component or not
    componentTag () {
      // return Array.isArray(this.config.options)
      return this.$utils.parseCompName(this.config.type)
    },

    // remote select component or not
    isRemoteSelect () {
      return (this.config.component || {}).remote
    }
  },

  methods: {
    // public methods

  }
}
</script>
<style lang="less" scoped>

</style>
