/**
 * v-permission 指令用于按钮级别的资源权限控制
 */
const install = (Vue, store) => {
  Vue.directive('permission', {
    inserted: function (el, binding) {
      const permissionStr = binding.value
      const hasPermission = store.state.app.sysPermission.find(item => {
        return item.permission === permissionStr
      })

      if (!hasPermission) {
        // IE兼容，IE中无remove方法
        el.remove ? el.remove() : el.parentElement.removeChild(el)
      }
    }
  })
}

export default install
