<template>
  <div class="custom-tooltip">
    {{ params.value }}
  </div>
</template>
<script>
import Vue from 'vue'
export default Vue.extend({
  data: function () {
    return {}
  }
})
</script>
<style lang="less" scoped>
.custom-tooltip {
  position: absolute;
  max-width: 250px;
  height: auto;
  border: 1px solid #888;
  background-color: #eee;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
  border-radius: 3px;
  padding: 5px;
}
</style>
