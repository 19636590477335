import { Notification } from 'element-ui'
import ReconnectWebsocket from '@/plugins/reconnecting-websocket'

const user = {
  state: {
    websocket: null
  },
  getters: {},
  actions: {},
  mutations: {
    INIT_WEBSOCKET (state, obj) {
      const userid = obj.userid
      const vue = obj.vue

      let baseWsUrl = window.location.origin.replace('http', 'ws') + '/api/websocket/' + userid
      if (window.localStorage.baseUrl) {
        baseWsUrl = window.localStorage.baseUrl.replace('http', 'ws') + '/websocket/' + userid
      }

      var websocket = new ReconnectWebsocket(baseWsUrl)

      websocket.onerror = function () {
        console.log('websocket.error')
      }

      // 连接成功建立的回调方法
      websocket.onopen = function (event) {
        console.log('onopen')
        websocket.send('ping')
      }

      // 接收到消息的回调方法
      websocket.onmessage = function (event) {
        try {
          const data = JSON.parse(event.data)
          console.log(data)

          let typeStr = ''
          const iexpType = data.iexpType

          typeStr = iexpType === 'iexp_type_exp' ? vue.$t('operation.export') : vue.$t('operation.import')

          let fileName = data.fileName
          if (iexpType === 'iexp_type_imp') fileName = data.fileName

          const websocketSuccess = `${typeStr} ${vue.$t('tip.websocketSuccess')}`
          const websocketError = `${typeStr} ${vue.$t('tip.websocketError')}`

          Notification({
            dangerouslyUseHTMLString: true,
            type: data.iexpStatus === 'iexp_status_success' ? 'success' : 'error',
            title: data.iexpStatus === 'iexp_status_success' ? vue.$t('tip.success') : vue.$t('tip.error'),
            duration: 0,
            onClick: () => {
              vue.$router.push({ name: 'system_task' }).catch(_ => {})
            },
            message: `<div style="word-break:break-all;">
              ${fileName} ${data.iexpStatus === 'iexp_status_success' ? websocketSuccess : websocketError}
              <div style="color:#409EFF;cursor:pointer;display:inline;">${vue.$t('tip.taskMenu')}</div>
              ${vue.$t('tip.forDetail')}
            </div>`
          })
        } catch (error) {

        }
      }

      // 连接关闭的回调方法
      websocket.onclose = function () {
        websocket.close()
        console.log('websocket.onclose')
      }

      // 监听窗口关闭事件，当窗口关闭时，主动去关闭 websocket 连接，防止连接还没断开就关闭窗口
      window.onbeforeunload = function () {
        websocket.close()
      }

      state.websocket = websocket
    },

    CLOSE_WEBSOCKET (state) {
      state.websocket.close()
    }

  }
}

export default user
