import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import Ready from '@/views/Ready'

Vue.use(Router)

// 项目中静态路由
const staticRoutes = [{
  path: '/login',
  name: 'login',
  component: () => import('@/views/Login.vue')
}, {
  path: '/',
  name: 'app',
  redirect: '/home',
  component: Ready,
  children: [{
    path: 'home',
    name: 'home',
    meta: {
      title: 'home.pageTitle'
    },
    component: () => import('@/views/Home')
  }, {
    path: '404',
    name: 'err404',
    meta: {
      title: 'page.err404'
    },
    component: () => import('@/views/Err404')
  }]
}]

const createRouter = () => new Router({
  scrollBehavior: () => {
    return {
      y: 0
    }
  },
  routes: staticRoutes
})

const router = createRouter()

let asyncRoutes
router.beforeEach((to, from, next) => {
  if (asyncRoutes || to.name === 'home' || to.name === 'login' || to.name === 'err404') {
    if (to.matched.length === 0) next('/404')
    else next()
  } else {
    const cacheMenuinfo = window.localStorage.menuinfo
    if (cacheMenuinfo && Array.isArray(JSON.parse(cacheMenuinfo))) {
      asyncRoutes = JSON.parse(cacheMenuinfo)
      addRoutes(asyncRoutes)
      next({ ...to, replace: true })
    } else {
      next({ name: 'home' })
    }
  }
})

router.afterEach(to => {
  store.dispatch('openNewPage', to)
})

// 递归解析 menu -> route
function parseMenuRecursive (menuItem, level = 1) {
  const routeInfo = {}
  routeInfo.path = `${level === 1 ? '/' : ''}${menuItem.name}`
  routeInfo.name = menuItem.name
  // 后端可加入 enTitle 字段，用于菜单的中英文翻译
  routeInfo.meta = { title: menuItem.title }

  // 需要后端配合
  /**
   * 新增 path 字段，用于存储每级的物理文件路径
   */

  if (level === 1) {
    routeInfo.component = Ready
    ++level
  } else {
    const path = menuItem.name.replace(/-|_/ig, '/')
    const targetFileName = menuItem.filename || 'list.vue'
    routeInfo.component = () => import('@/views/' + path + '/' + targetFileName)
    return routeInfo
  }
  // 多级目录处理，只需过滤出所有叶子节点，叶子节点作为一级路由的 children 数组元素即可
  const leafMenuItem = getAllLeafMenuItem(menuItem)
  if (leafMenuItem.length > 0) routeInfo.children = []

  leafMenuItem.forEach(leaf => {
    routeInfo.children.push(parseMenuRecursive(leaf, level))
  })

  return routeInfo
}

function getAllLeafMenuItem (menuItem) {
  const result = []

  function getLeafMenuItem (item) {
    const { children } = item
    if (Array.isArray(children) && children.length > 0) {
      children.forEach(childItem => getLeafMenuItem(childItem))
    } else {
      result.push(item)
    }
  }

  getLeafMenuItem(menuItem)
  return result
}

export function addRoutes (routeInfo) {
  // router.addRoutes()
  /**
   * 需要在此制定前后端路由匹配规则
   * 根据后端返回的 menu 数据结构解析为 route 结构
   */
  const routes = []

  routeInfo.forEach(routeItem => {
    const routeItemInfo = parseMenuRecursive(routeItem)
    routes.push(routeItemInfo)
  })

  router.addRoutes(routes)
}

/**
 * 重置 router
 */
export function resetRoutes () {
  const newRouter = createRouter()
  asyncRoutes = undefined
  router.matcher = newRouter.matcher
}

export default router
