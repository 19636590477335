import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

const requireI18n = require.context('@/views/', true, /i18n\.js$/)

const i18nModule = {}
requireI18n.keys().forEach(filename => {
  Object.assign(i18nModule, requireI18n(filename).default.cn || {})
})

const cn = {
  home: {
    pageTitle: '首页'
  },
  placeholder: {
    username: '请输入用户名',
    password: '请输入密码'
  },
  validate: {
    notBlank: '请输入',
    notSelect: '请选择',
    notZero: '不为0',
    notLessThanZero: '不能小于0',
    notNumber: '请输入数字',
    notInInterval: '不在区间内',
    notValid: '格式不正确',
    isRequired: '请完成必填项',
    length: '【{field}】长度应为{len}'
  },
  page: {
    err404: '404 Not Found'
  },
  dashboard: {},
  field: {
    operation: '操作',
    remarks: '备注',
    status: '状态'
  },
  operation: {
    search: '查询',
    create: '新增',
    view: '查看',
    edit: '编辑',
    delete: '删除',
    save: '保存',
    clear: '清空',

    submit: '提交',
    confirm: '确认',
    cancel: '取消',
    close: '关闭',

    approve: '提交审核',
    reject: '驳回',
    copy: '复制',
    complete: '完成',
    handle: '处理',
    download: '下载',
    addChild: '添加子节点',
    export: '导出',
    batchExport: '批量导出',
    import: '导入',
    batchImport: '批量导入',
    upload: '上传',
    uploadFile: '上传文件',
    logout: '登出系统',
    setting: '设置',
    preview: '预览',
    publish: '发布',
    generate: '生成',
    back: '回退',
    changePassword: '修改密码',
    templateDownload: '模板下载'
  },
  tip: {
    success: '成功',
    error: '错误',
    warning: '提示',
    systemLoading: '数据加载中',
    process: '执行中...',
    deleteSuccess: '删除成功',
    saveSuccess: '保存成功',
    approveSuccess: '提交成功',
    adjustSuccess: '调整成功',
    withdrawSuccess: '撤回成功',
    copySuccess: '复制成功',
    associateSuccess: '关联成功',
    collectSuccess: '汇总成功',
    generateSuccess: '生成成功',
    exportSuccess: '导出成功',
    publishSuccess: '发布成功',
    rollbackSuccess: '回退成功',

    confirmDelete: '确认删除当前记录？',
    confirmApprove: '确认提交审核当前记录？',
    confirmComplete: '确认完成当前记录？',
    confirmReject: '确认驳回当前记录？',
    confirmExport: '确认导出记录？',

    uploadTip: '点击上传。',
    uploadSuccess: '文件上传成功',

    defaultPassword: '默认密码为 123456',
    websocketSuccess: '成功， 请到菜单',
    websocketError: '失败，请到菜单',
    taskMenu: '系统设置 -> 任务列表',
    forDetail: '查看详情',

    fileIsEmpty: '上传的文件内容为空',
    fileTypeError: '文件类型错误'
  },
  error: {
    vrd_versionRepeat: '已存在相同版本，请尝试创建新版本！',
    vme_versionRepeat: '已存在相同版本，请尝试创建新版本！'
  },
  ...zhLocale,
  ...i18nModule
}

export default cn
