<template>
  <div
    ref="pagetagContainer"
    class="pagetag__wrapper"
  >
    <div
      ref="pagetagBody"
      class="pagetag--opened"
      :style="{marginLeft: marginLeft + 'px'}"
      @DOMMouseScroll="handleScroll"
      @mousewheel="handleScroll"
    >
      <el-tag
        v-for="(openedPageItem, index) in openedPageList"
        v-show="!(openedPageItem.name === 'home' && !$store.state.app.isShowDashboard)"
        :key="index"
        :closable="openedPageItem.name!=='home'"
        size="medium"
        :type="openedPageItem.name === $route.name && $_.isEqual(openedPageItem.query, $route.query) ? '' : 'info'"
        color="#FFF"
        @close="handlePageClose(openedPageItem, index)"
        @click.native="handlePageClick(openedPageItem)"
      >
        {{ parsePageTitle(openedPageItem.meta.title) }}
      </el-tag>
    </div>
    <div class="pagetag__operation">
      <el-dropdown
        size="small"
        trigger="click"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <i class="el-icon-error" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="closeAll">
            关闭所有
          </el-dropdown-item>
          <el-dropdown-item command="closeOther">
            关闭其他
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageTagController',
  props: {
    openedPageList: { type: Array, default: () => [] }
  },
  data () {
    return {
      marginLeft: 0
    }
  },
  methods: {
    handlePageClose (openedPageItem, index) {
      // 关闭的标签为当前的路由，则跳转到前一标签的路由页面，如果无前一个标签则跳转到后一标签
      if (openedPageItem.name === this.$route.name && this.$_.isEqual(openedPageItem.query, this.$route.query)) {
        if (index - 1 > -1) {
          this.$router
            .push(this.openedPageList[index - 1])
            .catch(_ => {})
        } else {
          if (this.openedPageList[index + 1]) {
            this.$router
              .push(this.openedPageList[index + 1])
              .catch(_ => {})
          } else {
            this.$router
              .push({ name: 'home' })
              .catch(_ => {})
          }
        }
      }
      this.openedPageList.splice(index, 1)
    },

    // dropdown 组件点击一项
    // 实现关闭所有或关闭其它标签页
    handleCommand (command) {
      if (command === 'closeAll') {
        // 关闭所有
        this.handlePageCloseAll()
      } else if (command === 'closeOther') {
        // 关闭其它
        let currentPageIndex = 0
        let homePage = {}
        this.openedPageList.forEach((item, idx) => {
          if (item.name === 'home') {
            homePage = item
          }
          if (item.name === this.$route.name && this.$_.isEqual(item.query, this.$route.query)) {
            currentPageIndex = idx
          }
        })

        this.openedPageList.splice(currentPageIndex + 1, this.openedPageList.length - currentPageIndex)
        this.openedPageList.splice(0, currentPageIndex)
        if (this.$route.name !== 'home') this.openedPageList.unshift(homePage)
      }
    },

    handlePageCloseAll () {
      if (this.openedPageList.length === 1 && this.openedPageList[0].name === 'home') return
      this.openedPageList.splice(0)
      this.$router
        .push({ name: 'home' })
        .catch(_ => {})
    },

    handlePageClick (openedPageItem) {
      this.$router
        .push(openedPageItem)
        .catch(_ => {})
    },

    handleScroll (event) {
      const eventType = event.type
      let left = 0
      let scrollDelta = 0

      // 可视区长度
      const pagetagContainerWidth = this.$refs.pagetagContainer.offsetWidth - 40
      // 页面标签总长度
      const pagetagBodyWidth = this.$refs.pagetagBody.offsetWidth

      if (eventType === 'mousewheel') {
        scrollDelta = event.wheelDelta
      }

      if (scrollDelta > 0) {
        // 鼠标滚轮向下滑动，即右移
        left = Math.min(0, this.marginLeft + scrollDelta)
      } else {
        // 标签总长度超出显示区域长度
        if (pagetagBodyWidth - pagetagContainerWidth > 0) {
          if (pagetagContainerWidth - pagetagBodyWidth > this.marginLeft) {
            left = this.marginLeft
          } else {
            // 滚动至最右侧标签
            left = Math.max(this.marginLeft + scrollDelta, pagetagContainerWidth - pagetagBodyWidth)
          }
        } else {
          left = 0
        }
      }
      this.marginLeft = left
    },
    parsePageTitle (pageTitle) {
      if (pageTitle.split('.').length >= 2) return this.$t(pageTitle)
      else return pageTitle
    }
  }
}
</script>
<style lang="less" scoped>
  .pagetag__wrapper {
    position: relative;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #f0f0f0;
    // padding-right: 40px;
    overflow: hidden;
    z-index: 80;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2);

    .pagetag--opened {
      position: relative;
      white-space: nowrap;
      overflow: visible;
      .el-tag {
        cursor: pointer;
        margin-left: 5px;
        &:last-child {
          margin-right: 5px;
        }
      }
    }

    .el-tag {
      border-radius: 0;
      &--info {
        color: #424242;
      }
    }

    .pagetag__operation {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      width: 40px;
      background: #fff;
      box-shadow: 0 0 5px 5px rgba(100, 100, 100, 0.1);
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
</style>
