import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import router from './router/index'
import './plugins'

import '@/components'
import i18n from './i18n/i18n'
import dictionary from './dictionary/index'
import authconfig from './permission/authconfig'
import permission from './permission/index'

// 导入样式文件
import '@/styles/index.less'
import 'ag-grid-enterprise'

Vue.use(dictionary)
Vue.use(permission, store)

Vue.prototype.$permission = authconfig
Vue.config.productionTip = false

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
