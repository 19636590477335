const cost = {
  cn: {
    cost: {
      menuTitle: '成本数据管理'
    },
    cost_wholesale: {
      menuTitle: '批售数据管理',
      month: '月份',
      name: '名称',
      importTime: '导入时间',
      pageTitle: '导入'
    },
    cost_vme: {
      menuTitle: 'VME数据管理',
      version: '版本',
      name: '名称',
      importTime: '导入时间',
      pageTitle: '导入'
    },
    cost_vrd: {
      menuTitle: 'VRD数据管理',
      version: '版本',
      name: '名称',
      importTime: '导入时间',
      pageTitle: '导入'
    }
  },

  en: {
    cost: {
      menuTitle: 'Cost Data Management'
    },
    cost_wholesale: {
      menuTitle: 'Wholesale Data Management',
      month: 'Month',
      name: 'Name',
      importTime: 'Import Time',
      pageTitle: 'Export'
    },
    cost_vme: {
      menuTitle: 'VME Management',
      version: 'Version',
      name: 'Name',
      importTime: 'Import Time',
      pageTitle: 'Import'
    },
    cost_vrd: {
      menuTitle: 'VRD Management',
      version: 'Version',
      name: 'Name',
      importTime: 'Import Time',
      pageTitle: 'Import'
    }

  }
}
export default cost
