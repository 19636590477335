const system = {
  cn: {
    config: {
      menuTitle: '参数设置'
    },
    config_deadline: {
      menuTitle: '提交截止日期设定',
      year: '年份',
      month: '月份',
      monthReportDeadline: '月报时间设置',
      monthReportUploadTime: '月报上传时间',
      startTime: '开始时间',
      endTime: '结束时间',
      jan: '一月份',
      feb: '二月份',
      mar: '三月份',
      apr: '四月份',
      may: '五月份',
      jun: '六月份',
      jul: '七月份',
      aug: '八月份',
      sep: '九月份',
      orc: '十月份',
      nov: '十一月份',
      dec: '十二月份',
      ifStart: '是否启动',
      dateRangeTip: '请完整填写开始时间和结束时间'
    },
    config_kpi: {
      menuTitle: 'KPI',
      kpi: 'KPI',
      detailDialog: 'KPI详细',
      name: '名称',
      sort: '排序',
      code: '编码',
      type: '类别',
      remark: '备注',
      fluctuationRange: '波动范围',
      thresholdRange: '阈值范围',
      fluctuationAbsoluteValue: '波动绝对值',
      calculationFormula: '计算公式',
      calculationFormulaCode: '公式代码',
      businessArea: '业务领域',
      formulaItem: '公式选项',
      formulaRequired: '请编辑计算公式',
      isJoin: '是否参与检查',
      isSpecial: '是否为特殊情况'
    },
    config_group: {
      menuTitle: '经销商分组',
      kpi: 'KPI',
      kpiName: 'KPI 名称',
      sort: '优先级',
      agency: '经销商',
      detailDialog: '经销商分组详细',
      bloc: '集团',
      name: '名称',
      select: '选择',
      batchThreshold: '批量阈值',
      batchFluctuation: '批量波动',
      createBy: '创建人',
      createDate: '创建时间',
      updateBy: '修改人',
      updateDate: '修改时间',
      fluctuationCheck: '波动检查',
      thresholdCheck: '阈值检查',
      logicalCheck: '逻辑检查',
      fluctuationRange: '波动范围',
      thresholdRange: '阈值范围',
      fluctuationAbsoluteValue: '波动绝对值',
      upperLimit: '自动计算上限',
      lowerLimit: '自动计算下限',
      manualUpperLimit: '手动计算上限',
      manualLowerLimit: '手动计算下限',
      checkRule: '逻辑检查规则',
      compareKpi: '对比 KPI',
      compareName: '对比 KPI 名称',
      compareCode: '对比 KPI 编码',
      compareType: '对比 KPI 类别',
      compareBusinessArea: '对比 KPI 业务领域',
      compareCalculationFormula: '对比 KPI 计算公式'
    },
    config_affair: {
      menuTitle: '勾稽关系设定',
      detailDialog: '勾稽关系详细',
      name: '名称',
      rule: '规则',
      sort: '排序',
      code: '编号',
      bizStatus: '是否生效'
    }
  },

  en: {
    config: {
      menuTitle: 'Parameter Setting'
    },
    config_deadline: {
      menuTitle: 'Submission Deadline Setting',
      year: 'Year',
      month: 'Month',
      monthReportDeadline: 'Monthly Report Submission Deadline',
      monthReportUploadTime: 'Monthly Report Submission Deadline',
      startTime: 'Start Time',
      endTime: 'End Time',
      jan: 'Jan.',
      feb: 'Feb.',
      mar: 'Mar.',
      apr: 'Apr.',
      may: 'May',
      jun: 'Jun.',
      jul: 'Jul.',
      aug: 'Aug.',
      sep: 'Sep.',
      orc: 'Oct.',
      nov: 'Nov.',
      dec: 'Dec.',
      ifStart: 'Enable',
      dateRangeTip: 'Please complete the start time and end time'
    },
    config_kpi: {
      menuTitle: 'KPI',
      kpi: 'KPI',
      detailDialog: 'KPI Detail',
      name: 'Name',
      sort: 'Sort',
      code: 'Code',
      type: 'Type',
      remark: 'Remarks',
      fluctuationRange: 'Variation Range (%)',
      thresholdRange: 'Threshold Range(%)',
      fluctuationAbsoluteValue: 'Absolute Value of Variation',
      calculationFormula: 'Formula',
      calculationFormulaCode: 'Formula Code',
      businessArea: 'Business',
      formulaItem: 'Formula Item',
      formulaRequired: 'Edit Formula',
      isJoin: 'Whether Check',
      isSpecial: 'Whether Special Case'
    },
    config_group: {
      menuTitle: 'Dealer Cluster',
      kpi: 'KPI',
      kpiName: 'KPI Name',
      sort: 'Preference',
      agency: 'Dealer',
      detailDialog: 'Dealer Cluster detail',
      bloc: 'Dealer Group',
      name: 'Name',
      select: 'Select',
      createBy: 'Creat By',
      createDate: 'Create Date',
      updateBy: 'Editor',
      updateDate: 'Edit Date',
      fluctuationCheck: 'Variation Check',
      thresholdCheck: 'Threshold Check',
      logicalCheck: 'Logical Check',
      fluctuationRange: 'Variation Range (%)',
      thresholdRange: 'Threshold Range(%)',
      fluctuationAbsoluteValue: 'Absolute Value of Variation',
      upperLimit: 'Automatic Calculate Upper Limit',
      lowerLimit: 'Automatic Calculate Lower Limit',
      manualUpperLimit: 'Manual Calculate Upper Limit',
      manualLowerLimit: 'Manual Calculate Lower Limit',
      checkRule: 'Logical Check Rule',
      compareKpi: 'KPI Comparison',
      compareName: 'KPI Name Comparison',
      compareCode: 'KPI Code Comparison',
      compareType: 'KPI Type Comparison',
      compareBusinessArea: 'KPI Business Comparison',
      compareCalculationFormula: 'KPI Formula Comparison'
    },
    config_affair: {
      menuTitle: 'Accounting Check Setting',
      detailDialog: 'Accounting Check Detail',
      name: 'Name',
      rule: 'Rule',
      sort: 'Sort',
      code: 'Code',
      bizStatus: 'Enable'
    }
  }
}
export default system
