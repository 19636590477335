<template>
  <el-dialog
    width="40%"
    :close-on-click-modal="false"
    :title="$t('system_password.pageTitle')"
    :visible.sync="currentShowFlag"
    @open="onOpen"
    @closed="onClose"
  >
    <el-form
      ref="changePwdForm"
      :model="changePwdModel"
      status-icon
      :rules="changePwdRules"
      label-width="80px"
      style="padding: 0 50px;"
    >
      <el-form-item
        :label="$t('system_password.currentPassword')"
        prop="oldPassword"
      >
        <el-input
          v-model="changePwdModel.oldPassword"
          type="password"
          auto-complete="off"
        />
      </el-form-item>
      <el-form-item
        :label="$t('system_password.newPassword')"
        prop="newPassword"
      >
        <el-input
          v-model="changePwdModel.newPassword"
          type="password"
          auto-complete="off"
        />
      </el-form-item>
      <el-form-item
        :label="$t('system_password.retypeNewPassword')"
        prop="confirmPassword"
      >
        <el-input
          v-model="changePwdModel.confirmPassword"
          type="password"
          auto-complete="off"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :disabled="sendFlag"
          @click="handleSendVerCode"
        >
          {{ buttonInfo }}
        </el-button>
      </el-form-item>
      <el-form-item
        :label="$t('system_password.veriCode')"
        prop="verCode"
      >
        <el-input
          v-model="changePwdModel.verCode"
          auto-complete="off"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="currentShowFlag = false">
        {{ this.$t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="confirm"
      >
        {{ this.$t('operation.save') }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
let verCode
export default {
  props: {
    showFlag: { type: Boolean, default: false },
    loginName: { type: String, required: true }
  },
  data () {
    return {
      changePwdModel: {},
      changePwdRules: {
        oldPassword: { required: true, message: this.$t('system_password.currentPasswordRequired'), trigger: 'blur' },
        newPassword: [
          { required: true, message: this.$t('system_password.newPwdRequired'), trigger: 'blur' },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={}\\[\]:;'"<,>.?/])[^]{8,}$/,
            message: this.$t('system_password.validateCheck')
          }
        ],
        confirmPassword: [
          { required: true, message: this.$t('system_password.retypeNewPasswordTip'), trigger: 'blur' },
          { validator: this.checkEquality, trigger: 'blur' }
        ],
        verCode: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
      },
      buttonInfo: '',
      sendFlag: false
    }
  },
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    }
  },
  methods: {
    onOpen () {
      this.buttonInfo = this.$t('system_password.sendVeriCode')
      this.changePwdModel = { loginName: this.loginName }
      let lastSendTime = localStorage.getItem('_sendVerCodeTime', this.$moment())

      if (lastSendTime && this.$moment().diff(this.$moment(lastSendTime), 'seconds') < 60) {
        let count = 60 - this.$moment().diff(lastSendTime, 'seconds')
        this.sendFlag = true
        verCode = setInterval(() => {
          if (count === 0) {
            this.buttonInfo = this.$t('system_password.regain')
            clearInterval(verCode)
            this.sendFlag = false
          } else {
            this.buttonInfo = `${count}S ${this.$t('system_password.regain')}`
          }
          count--
        }, 1000)
      }
    },
    onClose () {
      this.$refs.changePwdForm.resetFields()
      clearInterval(verCode)
      this.sendFlag = false
      this.buttonInfo = this.$t('system_password.sendVeriCode')
    },
    checkEquality (rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t('system_password.retypeNewPasswordTip')))
      } else if (value !== this.changePwdModel.newPassword) {
        callback(new Error(this.$t('system_password.notConsistent')))
      } else {
        callback()
      }
    },
    confirm () {
      this.$refs.changePwdForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          this.$axios
            .post('/system/userExtend/updateUserPassword', this.changePwdModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({ type: 'success', message: this.$t('tip.saveSuccess') })
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleSendVerCode () {
      localStorage.setItem('_sendVerCodeTime', this.$moment())
      let count = 60
      this.sendFlag = true
      verCode = setInterval(() => {
        if (count === 0) {
          this.buttonInfo = this.$t('system_password.regain')
          clearInterval(verCode)
          this.sendFlag = false
        } else {
          this.buttonInfo = `${count}S ${this.$t('system_password.regain')}`
        }
        count--
      }, 1000)
      this.$axios.post('/system/userExtend/sendVerificationCode', { loginName: this.loginName }).then(resp => {
      })
    }
  }
}
</script>
