<template>
  <el-dialog
    top="50px"
    width="400px"
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('operation.uploadFile') }}
    </div>
    <div class="simple-upload-con">
      <el-upload
        ref="simple-upload"
        :multiple="multiple"
        :auto-upload="autoUpload"
        :disabled="isUploading"
        :action="uploadAction"
        :headers="{Authorization: `Bearer ${appToken}`}"
        :data="params"
        :file-list="fileList"
        :on-success="handleSuccess"
        :on-error="handleError"
        :on-remove="handleRemove"
        :before-upload="beforeUpload"
        class="simple-upload"
      >
        <i
          :style="{fontSize:'40px',color:(isUploading?'#ddd':'#409EFF')}"
          class="el-icon-upload"
        />
        <div :style="{fontSize:'12px',color:(isUploading?'#ddd':'#aaa')}">
          {{ this.$t('tip.uploadTip') }}
        </div>
      </el-upload>
      <!-- 上传时，鼠标放在进度条上会显示对号，因此用个 overlay 覆盖 -->
      <div
        v-if="isUploading"
        class="overlay"
      />
    </div>
    <div style="text-align:right;margin:8px;">
      <el-button
        v-if="showConfirm"
        type="primary"
        @click="handleConfirm"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import baseUrl from '@/config/base-url'
export default {
  name: 'SimpleUpload',
  props: {
    url: { type: String, required: true },
    params: Object,
    fileType: { type: String },
    multiple: { type: Boolean, default: false },
    autoUpload: { type: Boolean, default: true },
    fileList: { type: Array, default: () => { return [] } },
    disabled: { type: Boolean, default: false },
    showFlag: { type: Boolean, default: false },
    showConfirm: { type: Boolean, default: false }
  },
  data () {
    return {
      loadingFlag: null
    }
  },
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    appToken () {
      return localStorage.appToken
    },
    uploadAction () {
      return `${baseUrl}${this.url}`
    },
    isUploading: {
      get () {
        return this.disabled
      },
      set (val) {
        this.$emit('update:disabled', val)
      }
    }
  },
  methods: {
    clearFiles () {
      this.$refs['simple-upload'].clearFiles()
    },
    setBusy (boolean) {
      this.isUploading = boolean
      if (boolean) {
        this.loadingFlag = this.$loading({ target: this.$el.querySelector('.simple-upload .el-upload') })
      } else {
        this.loadingFlag && this.loadingFlag.close()
      }
    },
    beforeUpload (file) {
      if (file.size === 0) {
        this.$message({ type: 'error', message: this.$t('tip.fileIsEmpty') })
        return false
      }
      if (this.fileType === 'excel') {
        const str = file.name
        const reg = /[^\\.](\.xls)$/i
        const reg2 = /[^\\.](\.xlsx)$/i
        const reg3 = /[^\\.](\.XML)$/i
        if (reg.test(str) || reg2.test(str) || reg3.test(str)) {
          this.setBusy(true)
        } else {
          this.$message({ type: 'warning', message: this.$t('tip.fileTypeError') })
          return false
        }
      }
      this.$emit('before-upload', file)
    },
    handleSuccess (response, file, fileList) {
      if (response.code === 200) {
        this.setBusy(false)
        this.$emit('on-success', response, file, fileList)
      } else {
        this.setBusy(false)
        this.$emit('on-error', response, file, fileList)
        this.$message({ type: 'warning', message: this.$t(`error.${response.message}`) })
        this.currentShowFlag = false
      }
    },
    handleRemove (file, fileList) {
      this.$emit('remove', file, fileList)
    },
    handleError (Err, file, fileList) {
      this.$alert('Upload Error', 'Error')
      this.$message({ type: 'warning', message: 'Upload Error' })
    },
    handleConfirm () {
      this.$emit('confirm', this)
      this.currentShowFlag = false
    },
    handleDialogOpen () {},
    handleDialogClosed () {
      this.$refs['simple-upload'].clearFiles()
      this.$refs['simple-upload'].abort()
    }
  }
}
</script>
<style lang="less" scoped>
.simple-upload-con{
  position: relative;
  border: 1px dashed #ddd;
  border-radius: 2px;
  padding: 10px 0;
  /deep/.simple-upload {
    .el-upload,
    .el-upload-dragger {
      width: 100%;
    }
  }
  .overlay{
    width: 100%;
    height: 36px;
    position: absolute;
    bottom: -10px;
  }
}
</style>
