<template>
  <el-container class="sk-container">
    <el-aside :width="sidebarWidth">
      <sidebar
        :is-collapse="isCollapse"
        :menu-list="menuList"
      />
    </el-aside>
    <el-container>
      <el-header
        height="40px"
        class="sk-header"
      >
        <i
          id="foldBtn"
          ref="foldBtn"
          :class="{expanded: !isCollapse, [isCollapse?'el-icon-s-unfold':'el-icon-s-fold']:true} "
          @click="handleSidebarCollapse"
        />
        <div class="sk-header__title" />
        <div class="sk-header__menuhandler">
          <language />
        </div>
        <div class="sk-header__profile">
          <el-dropdown
            trigger="click"
            size="medium"
            @command="handleCommand"
          >
            <span
              class="el-dropdown-link"
              style="cursor: pointer; color: #FFF; font-weight: bolder;"
            >
              {{ userInfo.name }}
              <i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="changePwd">
                {{ $t('operation.changePassword') }}
              </el-dropdown-item>
              <el-dropdown-item command="logout">
                {{ $t('operation.logout') }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-main class="sk-main">
        <page-tag-controller
          v-if="hasPageTagController"
          :opened-page-list="openedPageList"
        />
        <div
          class="sk-main_container"
          :class="{'sk-main__padding': hasPageTagController && $route.name !== 'home'}"
        >
          <keep-alive :include="cachedPageNameList">
            <router-view />
          </keep-alive>
        </div>
      </el-main>
      <change-password
        :show-flag.sync="changePwdVisible"
        :login-name="$store.state.app.userInfo.loginName"
      />
    </el-container>
  </el-container>
</template>

<script>
import {
  addResizeListener,
  removeResizeListener
} from '../components/util/resize-event'
import language from '../components/base/language'
import changePassword from '@/views/system/password/changePassword'

export default {
  name: 'MainView',
  components: { language, changePassword },
  data () {
    return {
      isCollapse: false,
      changePwdVisible: false,
      // 隐藏页面标签栏
      hasPageTagController: true
    }
  },
  computed: {
    menuList () {
      let currentMenu = this.$store.state.app.sysMenu
      currentMenu = currentMenu.filter(item => {
        return item.name.substr(0, 4) !== 'home'
      })
      return currentMenu
    },
    userInfo () {
      return this.$store.state.app.userInfo
    },
    sidebarWidth () {
      return this.isCollapse ? '64px' : '200px'
    },
    openedPageList () {
      return this.$store.state.app.openedPageList
    },
    cachedPageNameList () {
      // 如果隐藏页面标签栏，则不需要缓存历史打开的目录
      if (!this.hasPageTagController) return []

      // 过滤不需缓存的页面
      const needCachePageList = this.openedPageList.filter(openedPageListItem => {
        return (
          openedPageListItem.meta &&
          // meta 中无 cache 属性
          // 有 cache 属性并为 true
          (this.$_.isUndefined(openedPageListItem.meta.cache) ||
            openedPageListItem.meta.cache === true)
        )
      })
      return ['Home'].concat(
        needCachePageList.map(item => {
          // 配置菜单时，由于在数据库中，菜单的唯一命名以下划线分割
          // 但是，组件中的 name 属性为 camel 式命名
          // 所以，在 keep-alive 的 include 中，匹配的为组件的 name，需要做字符串的转换
          // 并且，严格准守 组件命名 与 menu name 根据规则匹配
          return this.$_.chain(item.name)
            .camelCase()
            .upperFirst()
            .value()
        })
      )
    }
  },
  mounted () {
    addResizeListener(this.$el, this.handleElementResize)
  },
  destroyed () {
    // 移除监听事件
    removeResizeListener(this.$el, this.handleElementResize)
  },
  methods: {
    handleSidebarCollapse () {
      this.isCollapse = !this.isCollapse
      this.$store.commit('updateIsCollapse', this.isCollapse)
    },

    handleCommand (command) {
      if (command === 'logout') {
        this.$router.replace({ name: 'login' })
        this.$axios
          .post('/auth/oauth/remove/token', {})
          .then(resp => {})
          .finally(() => {
            this.$store.commit('systemLogout')
          })
      } else if (command === 'changePwd') {
        this.changePwdVisible = true
      }
    },

    handleElementResize () {
      const mainHeight = this.$el.querySelector('.sk-main').offsetHeight - 40
      this.$el.querySelector('.sk-main_container').style.height = mainHeight + 'px'
    }
  }
}
</script>
<style lang="less" scoped>
.sk-container {
  height: 100%;
  .sk-header {
    display: flex;
    align-items: center;
    background-color: #424242;
    border-color: #424242;
    color: #FFFFFF;
    z-index: 5;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    .icon-expand {
      cursor: pointer;
    }

    &__title {
      color: #333;
      padding-left: 20px;
    }

    &__menuhandler {
      position: absolute;
      right: 160px;
      z-index: 1000;
    }

    &__profile {
      position: absolute;
      right: 20px;
      z-index: 1000;
    }
  }

  .sk-main {
    padding: 0;
    z-index: 4;

    &_container {
      padding: 10px;
    }
  }
}
</style>
