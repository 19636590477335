const value = {
  cn: {
    value: {
      menuTitle: '价值地图'
    },
    value_map: {
      menuTitle: '价值地图',
      dealerValueMap: '经销商价值地图',
      dmsCode: 'DMS 代码',
      profitabilityMap: '盈利地图',
      reload: '重载',
      dealerBaseInfo: '经销商基础信息',
      dealerShortName: '经销商简称',
      groupName: '集团名称',
      cityLevel: '城市级别',
      shopAge: '店龄',
      scale: '规模',
      area: '大区',
      province: '省份',
      city: '城市',
      cityMarketShare: '市场占有率',
      contrastType: '对标',
      reportPeriod: '报告期间',
      contrastDealerCount: '对标经销商数量',
      dealer: '经销商',
      level: '等级',
      light: '信号灯',
      levelRange: '等级区间',
      rank: '排名',
      indicatorDef: '指标定义',
      salesArea: '销售小区',
      afterSalesArea: '售后小区',
      dealerAge: '店龄群组'
    },
    value_map2: {
      menuTitle: '集团价值地图',
      dealerValueMap: '集团价值地图',
      dealerBaseInfo: '集团基础信息',
      groupName: '集团名称',
      groupDealerCount: '集团经销商数量',
      operationSize: '集团规模',
      contrastGroupCount: '对标集团数量'
    }
  },

  en: {
    map: {
      menuTitle: 'Value Map'
    },
    value_map: {
      menuTitle: 'Value Map',
      dealerValueMap: 'Dealer Value Map',
      dmsCode: 'DMS Code',
      profitabilityMap: 'Profitability Map',
      reload: 'Reload',
      dealerBaseInfo: 'Dealer Basic Info',
      dealerShortName: 'Dealer Short Name',
      groupName: 'Dealer Group',
      cityLevel: 'City Tier',
      shopAge: 'Dealer Age',
      scale: 'Size',
      area: 'Region',
      province: 'Province',
      city: 'City',
      cityMarketShare: 'Market Share',
      contrastType: 'Cluster',
      reportPeriod: 'Report Period',
      contrastDealerCount: 'Cluster Dealer Num',
      dealer: 'Dealer',
      level: 'Level',
      light: 'Light',
      levelRange: 'Level Range',
      rank: 'Rank',
      indicatorDef: 'KPI Definition',
      salesArea: 'Sub Region',
      afterSalesArea: 'Aftersales Sub Region',
      dealerAge: 'Dealer Age Group'
    },
    value_map2: {
      menuTitle: 'Dealer Group Value Map',
      dealerValueMap: 'Dealer Group Value Map',
      dealerBaseInfo: 'Dealer Group Basic Info',
      groupName: 'Dealer Group',
      groupDealerCount: 'Dealer Num',
      operationSize: 'Dealer Group Size',
      contrastGroupCount: 'Cluster Group Num'
    }
  }
}
export default value
