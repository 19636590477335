const share = {
  cn: {
    share: {
      menuTitle: '文件共享'
    },
    share_monthlyTemplate: {
      menuTitle: '月报模板管理',
      uploadNewTemplate: '上传新模板',
      setCurrent: '设为当前'
    },
    share_projectMaterial: {
      menuTitle: '项目资料管理',
      name: '资料名称',
      date: '上传日期',
      uploadNewFile: '上传新文件',
      publish: '发布',
      status: '发布状态',
      createBy: '创建人',
      generateSuccess: '发布成功'
    }
  },

  en: {
    share: {
      menuTitle: 'Share File'
    },
    share_monthlyTemplate: {
      menuTitle: 'Monthly Template Setting',
      uploadNewTemplate: 'Upload New Template',
      setCurrent: 'Enable'
    },
    share_projectMaterial: {
      menuTitle: 'Project Files Management',
      name: 'Name',
      date: 'Date',
      uploadNewFile: 'Upload New File',
      publish: 'Publish',
      status: 'Status',
      createBy: 'Create By',
      generateSuccess: 'Success'
    }
  }
}
export default share
