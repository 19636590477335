<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    top="10px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ title }}
    </div>
    <div style="height:500px;">
      <simple-table
        ref="table"
        :with-selection="multiple"
        :table-column="tableColumn"
        :table-data="tableData"
        highlight-current-row
        :table-page-info="tablePageInfo"
        :table-data-count="tableDataTotalCount"
        @page-info-change="handlePageInfoChange"
        @select="handleSelectionChange"
        @row-click="handleRowClick"
        @row-dblclick="handleRowDblclick"
      >
        <simple-form
          v-model="searchModel"
          :form-field="searchFormConfig"
        >
          <template slot="tableOperation">
            <el-button
              type="primary"
              @click="handleDataSearch"
            >
              {{ $t('operation.search') }}
            </el-button>
          </template>
        </simple-form>
        <template
          v-for="item in tableColumn"
          :slot="item.slotName"
          slot-scope="scope"
        >
          <!-- 多个字段使用同一个字典时，dict 属性可以写成：字段名-字典名，避免重复定义同名插槽产生渲染问题 -->
          {{ $getDictLabel({type: item.slotName.indexOf('-') > -1 ? item.slotName.split('-')[1] : item.slotName, value: scope.row[item.prop]}) }}
        </template>
      </simple-table>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClosed">
        {{ $t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="handleDataConfirm"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import pageMixins from '@/components/base/simple-table/mixin'
export default {
  name: 'SelectedTable',
  mixins: [pageMixins],
  props: {
    title: { type: String, default: '请选择' },
    showFlag: { type: Boolean, default: false },
    selectedData: { type: Array, default: () => [] },
    url: { type: String, required: true },
    params: { type: Object },
    tableColumn: { type: Array, required: true },
    searchFormField: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false }
  },
  data () {
    return {
      formModel: {},
      searchModel: {},
      tableData: []
    }
  },
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    selections: {
      get () { return this.selectedData },
      set (val) { this.$emit('update:selectedData', val) }
    },
    searchFormConfig () {
      return [
        ...this.searchFormField,
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          style: { textAlign: 'right' },
          col: { xs: 6, sm: 6, md: 6 }
        }
      ]
    }
  },
  watch: {
    tableData (data) {
      if (data.length) {
        this.$nextTick(() => {
          if (this.selectedData.length) {
            this.selectedData.forEach(row => {
              const newRow = data.find(ele => { return ele.id === row.id })
              this.$refs.table.toggleRowSelection(newRow, true)
            })
          }
        })
      }
    }
  },
  methods: {
    handleDataSearch () {
      if (this.params) {
        this.searchModel = Object.assign({}, this.params, this.searchModel)
      }
      const searchInfo = this.$utils.parseQueryCondition(this.searchModel, this.searchFormConfig)
      const params = Object.assign({}, { page: this.tablePageInfo }, searchInfo)
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios
        .post(this.url, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          this.tablePageInfo = { pageNo: respData.pageNo, pageSize: respData.pageSize }
          this.tableDataTotalCount = respData.count
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleSelectionChange (selections, row) {
      if (this.multiple) {
        this.selections = selections
      }
    },
    handleRowClick (row) {
      if (!this.multiple) {
        this.selections = [row]
      }
    },
    handleRowDblclick (row) {
      if (!this.multiple) {
        this.selections = [row]
        this.handleDataConfirm()
      }
    },
    handleDialogClosed () {
      this.currentShowFlag = false
    },
    handleDataConfirm () {
      this.$emit('confirm', this.selections)
      this.handleDialogClosed()
    },
    handleDialogOpen () {
      this.searchModel = {}
      this.handleDataSearch()
    }
  }
}
</script>
