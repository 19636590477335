const pageMixins = {
  data () {
    return {
      tablePageInfo: {
        pageNo: 1,
        pageSize: 10
      },
      tableDataTotalCount: 0
    }
  },
  methods: {
    handlePageInfoChange () {
      this.handleDataSearch()
    },
    handleSortChange ({ column, prop, order }) {
      switch (order) {
        case 'ascending':
          this.tablePageInfo.orderBy = prop
          this.tablePageInfo.orderSort = 'asc'
          break
        case 'descending':
          this.tablePageInfo.orderBy = prop
          this.tablePageInfo.orderSort = 'desc'
          break
        default:
          delete this.tablePageInfo.orderBy
          delete this.tablePageInfo.orderSort
      }
      this.handleDataSearch()
    }
  }
}
export default pageMixins
