const system = {
  cn: {
    thirdParty: {
      menuTitle: '第三方数据审阅'
    },
    thirdParty_reportReview: {
      menuTitle: '报表审阅',
      anomalyReview: '异常指标第三方审阅',
      checkReview: '审阅结果',
      adjustEntries: '调整分录',
      otherDocs: '其他支持性文档',
      import: '导入异常',
      export: '导出异常',
      backToV2: '回退V2'
    },
    thirdParty_reportExport: {
      menuTitle: '报表下载',
      month: '月份',
      date: '日期',
      status: '状态',
      rebatesReplace: '返利替代',
      dataSource: '数据源',
      costData: '成本数据',
      versionName: '版本名',
      period: '周期',
      generate: '生成',
      replaceRecord: '替换记录',
      validateTip: '请填写完整',
      kpiExport: 'KPI 导出',
      version: '版本'
    },
    thirdParty_appealContent: {
      menuTitle: '反馈内容查看',
      appealContent: '反馈内容',
      viewAppealContent: '查看反馈内容',
      thirdPartyReply: '第三方回复',
      finalConfirm: '最终判定'
    },
    thirdParty_dealerGrade: {
      menuTitle: '经销商评分',
      submitData: '及时提交数据',
      replyMail: '及时回复邮件',
      uploadSupportingDocuments: '及时上传支持性文件',
      confirmReview: '及时确认复核',
      score: '通过与否'
    },
    thirdParty_unsubmittedDealer: {
      menuTitle: '提交管理  '
    },
    thirdParty_preset: {
      menuTitle: '异常指标解释预设'
    }
  },

  en: {
    thirdParty: {
      menuTitle: 'Third Party Audit'
    },
    thirdParty_reportReview: {
      menuTitle: 'Report Audit',
      anomalyReview: 'Abnormal Items Audit',
      checkReview: 'Audit Result',
      adjustEntries: 'Adjustment Entry',
      import: 'Import Exception',
      export: 'Export Exception',
      backToV2: 'Back to V2'
    },
    thirdParty_reportExport: {
      menuTitle: 'Report Download',
      month: 'Month',
      date: 'Date',
      status: 'Status',
      rebatesReplace: 'Rebate Replacement',
      dataSource: 'Data Source',
      costData: 'Cost Data',
      versionName: 'Version',
      period: 'Period',
      generate: 'Generate',
      kpiExport: 'KPI Export',
      version: 'Version',
      replaceRecord: 'Replacement Log',
      validateTip: 'Please complete'
    },
    thirdParty_appealContent: {
      menuTitle: 'View The Appeal',
      viewAppealContent: 'View The Appeal',
      thirdPartyReply: 'Third Party Comments',
      finalConfirm: 'Final Confirm'
    },
    thirdParty_dealerGrade: {
      menuTitle: 'Dealer score',
      submitData: 'Timely submission of data',
      replyMail: 'Timely reply email',
      uploadSupportingDocuments: 'Timely submission of supporting documents',
      confirmReview: 'Confirm in time',
      score: 'Pass or Fail'
    },
    thirdParty_unsubmittedDealer: {
      menuTitle: 'Commit Management'
    }

  }
}
export default system
