import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'

import router from '../router'
import baseUrl from '../config/base-url'

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 500000,
  responseType: 'json',
  withCredentials: true // 是否允许带cookie这些
})

const pending = []
const CancelToken = axios.CancelToken

const removePending = ever => {
  for (const p in pending) {
    if (pending[p].u === `${ever.url}&${ever.method}`) {
      pending[p].f('Cancel')
      pending.splice(p, 1)
    }
  }
}

instance.interceptors.request.use(config => {
  removePending(config)

  config.cancelToken = new CancelToken(c => {
    pending.push({
      u: `${config.url}&${config.method}`,
      f: c
    })
  })

  const appToken = localStorage.appToken
  if (appToken) config.headers.Authorization = `Bearer ${appToken}`

  return config
}, error => {
  return Promise.reject(error)
})

instance.interceptors.response.use(resp => {
  removePending(resp.config)
  const respData = resp.data

  if ((respData.ok && respData.code === 200) || respData.access_token || respData.type === 'application/octet-stream') return respData
  else {
    Message.error(respData.message)
    return Promise.reject(respData)
  }
}, error => {
  if (error.message && error.message === 'Cancel') return Promise.reject(error)
  else {
    const errResp = error.response
    if (errResp && errResp.data) {
      if (errResp.status === 401) {
        router.replace({
          name: 'login'
        })
        Message.error(errResp.data.error)
      } else {
        Message.error(errResp.data.message)
      }
      return Promise.reject(error)
    }

    Message.error(error.message)
    return Promise.reject(error)
  }
})

Vue.use({
  install (Vue) {
    // 采用这种方式定义属性，不会被修改和删除
    Object.defineProperty(Vue.prototype, '$axios', { value: instance })
  }
})
