import { render, staticRenderFns } from "./ag-grid.vue?vue&type=template&id=60fb1c66&scoped=true&"
import script from "./ag-grid.vue?vue&type=script&lang=js&"
export * from "./ag-grid.vue?vue&type=script&lang=js&"
import style0 from "./ag-grid.vue?vue&type=style&index=0&id=60fb1c66&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60fb1c66",
  null
  
)

export default component.exports