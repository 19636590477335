<script>
export default {
  name: 'Sidebar',
  props: {
    menuList: { type: Array, required: true },
    isCollapse: { type: Boolean, default: false }
  },
  data () {
    return {
      activeMenuName: 'home'
    }
  },
  watch: {
    $route: {
      // 点击标签页，动态设置菜单的 active 属性
      immediate: true,
      handler (to) {
        this.$nextTick(() => {
          this.activeMenuName = to.name
        })
      }
    }
  },
  methods: {
    getItem (menuItem) {
      if (menuItem.children && menuItem.children.length > 0) {
        return this.getSubMenu(menuItem)
      } else {
        return this.getMenuItem(menuItem)
      }
    },

    /**
     * 多级菜单递归调用，实现无层级限制菜单
     */
    getSubMenu (menuItem) {
      const { getItem } = this
      return (
        <el-submenu index={menuItem.name}>
          <template slot="title">
            {menuItem.icon && <i class={'iconfont ' + menuItem.icon}></i>}
            {this.getMenuTitle(menuItem.title)}
          </template>
          {menuItem.children.map(item => {
            return getItem(item)
          })}
        </el-submenu>
      )
    },

    getMenuItem (menuItem) {
      return (
        <el-menu-item index={menuItem.name}>
          {menuItem.icon && <i class={'iconfont ' + menuItem.icon}></i>}
          {this.getMenuTitle(menuItem.title)}
        </el-menu-item>
      )
    },

    getMenuTitle (title) {
      return <span> {this.$t(title)} </span>
    },

    handleMenuSelect (routeName) {
      // this.$nextTick(_ => {
      this.$router
        .push({ name: routeName })
        .catch(_ => {})
      // })
    }
  },
  // 通过 render 函数，实现组件
  render (h) {
    const { menuList, activeMenuName, isCollapse, getItem, handleMenuSelect } = this
    return (
      <el-menu
        class="sk-menu"
        unique-opened
        background-color="#424242"
        text-color="#fff"
        active-text-color="#fff"
        collapse-transition={false}
        default-active={activeMenuName}
        collapse={isCollapse}
        on-select={handleMenuSelect}
      >
        <div class="sk-menu_logo" style={!isCollapse ? '' : 'background:none'}>
        </div>
        {menuList.map(menuItem => {
          return getItem(menuItem)
        })}
      </el-menu>
    )
  }
}
</script>
<style lang="less" scoped>
.sk-menu {
  height: 100%;

  &_logo {
    height: 40px;
    // background: #85b4d0;
    // background: rgba(133, 180, 208, 0.1);
    background-color: white;
    background-image: url('../../../../assets/logo.png');
    background-size: 132px 32px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  /deep/ .el-menu-item.is-active {
    font-weight: bolder;
    color: #409eff !important;

    // &:before {
    //   content: '';
    //   display: inline-block;
    //   width: 5px;
    //   height: 100%;
    //   background-color: #FFF;
    // }
  }
}
</style>
