const summary = {
  cn: {
    summary: {
      menuTitle: '数据汇总'
    },
    summary_report: {
      menuTitle: '报表数据汇总',
      dataSummary: '数据汇总',
      name: '报表名称',
      date: '日期',
      month: '月份',
      dealerGroup: '经销商集团',
      dealerCode: '经销商编码',
      version: '版本'
    },
    summary_afterReport: {
      menuTitle: '审阅后替代后数据汇总',
      uploadNewFile: '上传新文件',
      year: '年份',
      period: '期间',
      version: '版本',
      name: '文件名',
      uploadTime: '上传时间'
    }
  },

  en: {
    summary: {
      menuTitle: 'Data Aggregation'
    },
    summary_report: {
      menuTitle: 'Report Data Aggregation',
      dataSummary: 'Data Aggregation',
      name: 'Report Name',
      date: 'Date',
      month: 'Month',
      dealerGroup: 'Dealer Group',
      dealerCode: 'Dealer Code',
      version: 'Version'
    },
    summary_afterReport: {
      menuTitle: 'Audit KPI Raw Data',
      uploadNewFile: 'Upload',
      year: 'Year',
      period: 'Period',
      version: 'Version',
      name: 'Name',
      uploadTime: 'Upload Time'
    }

  }
}
export default summary
