/**
 * baseUrl
 * 支持在 localStorage 中配置临时地址，用于与后端联调
 * 优点：支持热加载
 * 缺点：需要浏览器支持跨域
 */
let baseUrl = '/api' // default
const local = window.localStorage.getItem('baseUrl')
if (local) {
  baseUrl = local
  console.warn(`[app] This baseUrl "${baseUrl}" is in use.`)
}

export default baseUrl
