import { isEqual } from 'lodash'
import { resetRoutes } from '@/router'

/**
 * 递归循环筛选 type 为 button 的资源权限
 */
function getPermission (rootMenu, sysPermission) {
  if (rootMenu && rootMenu.length > 0) {
    rootMenu.forEach(item => {
      if (item.type === 'button') {
        sysPermission.push(item)
      }
      getPermission(item.children, sysPermission)
    })
  }
}
function filterMenu (menuList) {
  return menuList.filter(item => {
    return item.type === 'menu'
  }).map(item => {
    item = Object.assign({}, item)
    if (item.children && item.children.length) {
      item.children = filterMenu(item.children)
    }
    return item
  })
}

const app = {
  state: {
    appToken: '',
    sysMenu: [],
    sysPermission: [],
    sysDict: [],
    userInfo: {},
    // 用于存储已经打开的页面
    openedPageList: [],

    // 用户是否有显示首页的权限
    isShowDashboard: false,
    // 菜单是否折叠
    isCollapse: false

  },
  mutations: {
    // 请求凭证 token
    updateAccessToken (state, accessToken) {
      state.appToken = accessToken
      localStorage.setItem('appToken', accessToken)
    },
    // 更新菜单折叠flag
    updateIsCollapse (state, isCollapse) {
      state.isCollapse = isCollapse
    },
    /** 菜单数据初始化 */
    initializeMenu (state, sysMenu) {
      const sysPermission = []
      getPermission(sysMenu, sysPermission)
      state.sysPermission = sysPermission

      // 过滤掉非菜单类型（资源权限）
      const filterTypeMenu = filterMenu(sysMenu)
      state.sysMenu = filterTypeMenu

      // 首页标签固定
      const homeRoute = sysMenu.find(item => item.name === 'home')
      const homePageOpened = state.openedPageList.find(item => item.name === 'home')
      if (homeRoute) {
        state.isShowDashboard = true
        if (!homePageOpened) {
          if (window.localStorage.homepage) state.openedPageList.unshift(JSON.parse(window.localStorage.homepage))
        }
      } else state.isShowDashboard = false

      // 菜单数据写入缓存
      window.localStorage.menuinfo = JSON.stringify(filterTypeMenu)
    },
    /** 字典数据初始化 */
    initializeDictionary (state, sysDict) {
      state.sysDict = sysDict
    },
    /** 省市区数据初始化 */
    initializeArea (state, sysArea) {
      state.sysArea = sysArea
    },
    /** 设置用户信息 */
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },

    setShowDashboardFlag (state, flag) {
      state.isShowDashboard = flag
    },

    addPageTag (state, tagInfo) {
      // 首页信息写入缓存
      if (tagInfo.name === 'home') window.localStorage.homepage = JSON.stringify(tagInfo)

      // 新增通过判断 query 路由参数，来判断是否为同一个页面
      const tagExisted = state.openedPageList.find(pageInfo => {
        return (pageInfo.name === tagInfo.name && isEqual(pageInfo.query, tagInfo.query))
      })
      if (!tagExisted) state.openedPageList.push(tagInfo)
    },

    systemLogout (state) {
      resetRoutes()
      delete window.localStorage.menuinfo
      state.openedPageList.splice(0)
    }
  },
  actions: {
    openNewPage ({ commit }, to) {
      /** TODO:Permission 可以根据权限进行控制 */
      if (to.name !== 'login' && to.name) {
        commit('addPageTag', to)
      }
    }
  },
  getters: {}
}

export default app
