<template>
  <el-date-picker
    v-model="value"
    style="width: 100%"
    v-bind="params.componentProp"
    v-on="params.event"
  />
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data () { return { value: null } },
  created () {
    this.value = this.params.value
  },
  methods: {
    getValue () {
      return this.value
    }
  }
})
</script>
