const dealer = {
  cn: {
    dealer: {
      menuTitle: '经销商数据管理'
    },
    dealer_finance: {
      menuTitle: '财务报表收集',
      pageTitle: '经销商数据管理财务报表',
      detailDialog: '财务报表',
      dealerName: '经销商名称',
      dealerCode: '经销商代码',
      cycle: '周期',
      area: '区域',
      group: '集团',
      dealer: '经销商',
      reporter: '填报人',
      reporterPosition: '填报人职位',
      reporterPhone: '填报人电话',
      reporterEmail: '填报人邮箱',
      version: '版本',
      month: '月份',
      status: '状态',
      createDate: '上报时间',
      createTime: '创建时间',
      submitDate: '提交时间',
      updateDate: '修改时间',
      confirmDate: '确认时间',
      isSubmit: '提交截止',
      isPublish: '是否发布',
      reviewConfirmStatus: '审阅确认状态',
      reviewConfirmUser: '审阅确认人',
      submitTip: '提交后无法修改，请确认是否提交?',
      balance: {
        templateTitle: '选择月份',
        pageTitle: '资产负债表',
        accountCategory: '科目类别',
        firstLevelAccount: '一级科目',
        secondLevelAccount: '二级科目',
        thirdLevelAccount: '三级科目',
        openinBalance: '期初余额',
        benchmarkReport: '基准报表',
        adjustment: '调整',
        lend: '借',
        loan: '贷',
        adjustmentReport: '调整后报表',
        remark: '备注'
      },
      incomeStatement: {
        pageTitle: '损益表',
        accountCategory: '科目类别',
        firstLevelAccount: '一级科目',
        secondLevelAccount: '二级科目',
        thirdLevelAccount: '三级科目',
        fourthLevelAccount: '四级科目',
        fifthLevelAccount: '五级科目',
        openinBalance: '期初余额',
        benchmarkReport: '基准报表',
        adjustment: '调整',
        lend: '借',
        loan: '贷',
        adjustmentReport: '调整后报表',
        remark: '备注'
      },
      adjustingEntries: {
        pageTitle: '调整分录汇总表',
        dealerCode: '经销商代码：',
        dealerName: '经销商名称：',
        reporter: '填报人员：',
        order: '分录序号',
        lendLoan: '借/贷',
        account: '科目',
        adjustmentAmount: '调整金额',
        adjustmentRemark: '调整内容说明（说明分录调整原因，如使用“特殊损益调整”科目，说明所替代的原资产负债表科目名称）',
        updateBy: '操作人',
        debtor: '借方',
        creditor: '贷方',
        validateTip: '请正确填写借贷金额',
        validateTip1: '提示：借贷未平（借方：',
        validateTip2: '，贷方：',
        validateTip3: '借贷未平，无法提交'
      },
      operatingData: {
        pageTitle: '经营数据表',
        dealerCode: '经销商代码：',
        dealerName: '经销商名称：',
        reporter: '填报人员：',
        personnelInformationTable: '表 2-1 人员信息表',
        vehicleSaleDepPersonInfo: '表 2-1-1 车辆销售部门人员信息表',
        period: '所属时期：',
        time: '所属时点：',
        classifyByDep: '按部门分类',
        staffNumber: '人员数量',
        incomeStmPayrollAcc: '损益表工资费用核算科目',
        currentAmount: '本期金额',
        perCapitaMonthlySalary: '人均月工资',
        specialCaseNote: '备注（如有特殊情况，请备注说明）',
        afterSalesDepPersonInfo: '表 2-1-2 售后部门人员信息表',
        adminPersonInfo: '表 2-1-3 行政管理部门人员信息表',
        usedCarBizData: '表 2-2 二手车业务数据表',
        category: '类别',
        detail: '明细',
        numberTimes: '辆次',
        incomeStmUsedCarGrossPrfAcc: '损益表二手车毛利核算科目',
        money: '金额',
        singleIncome: '单笔收入',
        reportRemark: '填报说明',
        afterSalesBizStatistics: '表 2-3 售后业务统计表',
        project: '项目',
        unit: '单位',
        amount: '数量',
        remark: '说明',
        newVehicleSalesRebate: '表 2-4 新车销售返利（VME）-金融贴息',
        locationTable: '表 2-5 场地情况表',
        isSiteOwned: '是否为自有场地',
        siteLeaseTable: '表 2-5-1 场地租赁情况表（无论本期是否支付租金，只要有租赁情况就必须填报）',
        brand: '所属品牌',
        monthlyRent: '月租金（元）',
        totalLeaseholdArea: '总租赁面积（平方米）',
        monthlyRentPerSqMeter: '月租金/平方米',
        specialCaseNote2: '备注（特殊情况，请详细说明，例如租赁物为免费使用）',
        totalCurrentMonthlyRent: '本月应计租金合计',
        top5ReceiveBalance: '表 2-6-1 应收账款余额前5名（包含关联方及非关联方）',
        top5TotalReceiveBalance: '应收账款前5名合计',
        serialNumber: '序号',
        receiveBalanceCoName: '应收账款公司全称',
        relationship: '与本公司关系',
        useOfProceeds: '款项用途',
        top5AdvanceBalance: '表 2-6-2 预付款项余额前5名（包含关联方及非关联方）',
        top5TotalAdvanceBalance: '预付款项前5名合计',
        advanceBalanceCoName: '预付款项公司全称',
        top5OtherReceiveBalance: '表 2-6-3 其他应收款余额前5名（包含关联方及非关联方）',
        top5TotalOtherReceiveBalance: '其他应收款前5名合计',
        otherReceiveBalanceCoName: '其他应收款公司全称',
        top5PayBalance: '表 2-6-4 应付账款余额前5名（包含关联方及非关联方）',
        top5TotalPayBalance: '应付账款前5名合计',
        PayBalanceCoName: '应付账款公司全称',
        top5AdvanceReceiveBalance: '表 2-6-5 预收款项余额前5名（包含关联方及非关联方）',
        top5TotalAdvanceReceiveBalance: '预收款项前5名合计',
        advanceReceiveCoName: '预收款项公司全称',
        top5OtherPayBalance: '表 2-6-6 其他应付款余额前5名（包含关联方及非关联方）',
        top5TotalOtherPayBalance: '其他应付款前5名合计',
        otherPayBalanceCoName: '其他应付款公司全称',
        afterSalesPartsInventoryAge: '表 2-7 售后配件库存库龄统计表',
        validateTip1: '有工资无人员数量，请改正',
        validateTip2: '有人员数量无工资，请改正',
        validateTip3: '有收入无销量，请改正',
        validateTip4: '有销量无收入，请改正',
        validateTip5: '请填写金额',
        validateTip6: '请填公司全称',
        validateTip7: '请填写款项用途',
        validateTip8: '金额不得小于0，请改正',
        validateTip9: '请填写与本公司关系'
      },
      newVehicleRetail: {
        tableTitle: '新车零售明细信息表',
        vin: '车架号',
        currenPastSales: '当期销售/往期销售',
        vehicleName: '车型名称',
        purchaseSource: '采购来源',
        allocatingSources: '调拨来源',
        salesCategory: '销售类别',
        nakedVehicleWithoutTax: '新车裸车收入（不含税）',
        newVehicleCost: '新车成本（不含税）',
        MSRP: 'MSRP（不含税）',
        grossProfit1: '毛利一',
        VRD: 'VRD（不含税）',
        VME: 'VME（不含税）',
        grossProfit2: '毛利二',
        derivativeBusinessInfo: '新车衍生业务明细信息',
        financialServiceIncome: '新车金融服务收入（不含税）',
        insuranceIncome: '新车保险服务收入（不含税）',
        stuffSalesIncome: '新车车辆用品销售收入（不含税）',
        stuffSalesCost: '新车车辆用品销售成本（不含税）',
        extendWarrantyIncome: '新车延保销售收入（不含税）',
        extendWarrantySales: '新车延保销售成本（不含税）',
        otherServiceIncome: '新车其他服务收支（不含税）',
        otherServiceCost: '新车类现金赠送成本（不含税）',
        includingCashDiscountAmount: '其中：现金赠送金额',
        includingFuelGiftCardDiscountAmount: '其中：油卡赠送金额',
        includingShoppingGiftCardDiscountAmount: '其中：购物卡赠送金额',
        includingMaintenanceServiceDiscountAmount: '其中：保养赠送金额（不含税）',
        includingOtherDiscountAmount: '其中：其他赠送金额（不含税）',
        salesPresent: '新车销售实物赠送成本（不含税）',
        grossProfit3: '毛利三',
        formulaArea: '公式区',
        jbOrLh: '捷豹/路虎',
        domesticOrImport: '进口/国产'

      },
      newVehicleNotRetail: {
        tableTitle: '新车非零售明细信息表',
        vin: '车架号',
        modelName: '车型名称',
        beTransferred: '被调入方',
        salesCategory: '销售类别',
        incomeWithoutTax: '收入（不含税）',
        costWithoutTax: '成本（不含税）',
        grossProfit1: '毛利一',
        formulaArea: '公式区',
        vinCheck: 'VIN校验',
        jbOrLh: '捷豹/路虎',
        domesticOrImport: '进口/国产',
        repeatCheck: '重复校验'
      },
      notNewVehicleVme: {
        tableTitle: '非新车销售VME明细信息表',
        vin: '车架号',
        modelName: '车型名称',
        salesCategory: '销售类别',
        receiptAmount: '发票金额（不含税）',
        purchaseCost: '采购成本（不含税）',
        MSRP: 'MSRP（不含税）',
        VME: 'VME（不含税）',
        vinCheck: 'VIN校验',
        jbOrLh: '捷豹/路虎',
        formulaArea: '公式区',
        domesticOrImport: '进口/国产'
      },
      newVehicleRetailProfit: {
        tableTitle: '新车零售毛利数据自查',
        model: '车型',
        income: '收入',
        cost: '成本'
      },
      usedCarBusiness: {
        tableTitle: '二手车业务明细表',
        carType: '车型',
        carAge: '车龄',
        numOfSets: '台次',
        income: '二手车收入（不含税）',
        grossProfit: '二手车毛利（不含税）',
        title411: '除试驾车/代步车以外的认证二手车处置业务',
        title412: '退役试驾车/公务车处置业务',
        title413: '退役代步车处置业务',
        title421: '非认证本品和非本品二手车处置业务',
        title431: '二手车衍生业务',
        title44: '二手车库存情况统计表',
        brand: '品牌',
        insuranceNumOfSets: '二手车保险业务台次',
        insuranceGrossProfit: '二手车保险业务毛利',
        financeNumOfSets: '二手车金融服务台次',
        financeGrossProfit: '二手车金融服务毛利',
        boutiquesSaleIncome: '二手车精品附件销售收入',
        boutiqueSaleCost: '二手车精品附件销售成本',
        otherIncome: '二手车其他衍生业务收入',
        otherCost: '二手车其他衍生业务成本',
        servicingCost: '二手车衍生业务合计',
        // servicingCost: '二手车整备费用',
        inventoryDays: '库存天数',
        inventoryNumOfSets: '库存台次',
        inventoryAmount: '库存金额（单位：元）'
      }
    },
    dealer_document: {
      menuTitle: '其他支持性文档上传',
      month: '月份',
      needed: '需要上传文件',
      actual: '实际上传文件',
      uploadDownload: '上传下载'
    },
    dealer_anomaly: {
      menuTitle: '异常指标解释',
      fluctuationCheck: '波动检查',
      thresholdCheck: '阈值检查',
      logicalCheck: '逻辑检查',
      anomalyIndicatorSummary: '异常指标汇总',
      dealerReport: '经销商填报',
      indicatorName: '指标名称',
      compareIndicatorName: '对比指标名称',
      riskLevel: '风险等级',
      kpiFormula: 'KPI公式',
      lastData: '上期数据',
      currentData: '本期数据',
      fluctuation: '变动',
      fluctuationRate: '变动率',
      dealerExplain: '经销商解释',
      evidenceDocs: '补充证明文件',
      upperThreshold: '阈值上限',
      lowerThreshold: '阈值下限',
      anomalyReason: '异常原因',
      addDocs: '添加附件',
      fileList: '附件列表',
      viewAttachments: '查看附件列表',
      anomalyExplain: '异常指标解释',
      anomalyDealerExplain: '异常指标经销商解释',
      dataModifyAjustEntry: '数据修改/调整分录'
    },
    dealer_report: {
      menuTitle: '经销商报表下载',
      name: '报表名称',
      date: '日期',
      month: '月份'
    },
    dealer_reviewReport: {
      menuTitle: '审阅后报表下载',
      name: '报表名称',
      date: '日期',
      month: '月份'
    },
    dealer_entryConfirm: {
      month: '月份',
      dr: '借贷方',
      accountName: '科目名称',
      money: '金额',
      entryConfirm: '调整分录录入确认',
      updateBy: '修改人'
    },
    dealer_reviewConfirm: {
      menuTitle: '审阅确认函',
      confirm: '确认',
      appeal: '反馈',
      appealBy: '反馈人',
      reviewConfirm: '审阅确认',
      confirmBy: '确认人',
      appealContent: '反馈内容',
      uploadFiles: '上传附件',
      attachment: '下载附件',
      ifConfirm: '是否已确认',
      appealTip: '请完善反馈内容'
    },
    dealer_invoice: {
      menuTitle: '发票数据',
      type: '发票种类',
      dealerCode: '经销商代码',
      dealerName: '销货单位名称',
      period: '报表周期',
      invoiceDate: '开票日期',
      invoiceCode: '发票代码',
      invoiceNo: '发票号码',
      purchaseCo: '购货单位',
      vin: '车架号',
      income: '发票不含税金额',
      taxRate: '税率',
      tax: '发票税额',
      incomeTax: '价税合计',
      invoiceStatus: '发票状态',
      invoiceConfirmType: '发票确认分类',
      reason: '本期不确认原因解释',
      operationRole: '操作角色'
    }
  },

  en: {
    dealer: {
      menuTitle: 'Dealer Data Management'
    },
    dealer_finance: {
      menuTitle: 'Report Collection',
      pageTitle: 'Dealer Management Report',
      detailDialog: 'Financial Report',
      dealerName: 'Dealer Code',
      cycle: 'Period',
      area: 'Region',
      dealer: 'Dealer',
      reporter: 'Create By',
      reporterPosition: 'Creator Position',
      reporterPhone: 'Creator Phone',
      reporterEmail: 'Creator Email',
      version: 'Version',
      month: 'Month',
      status: 'Status',
      createDate: 'Create Date',
      submitDate: 'Submit Date',
      isSubmit: 'Whether Submitted',
      isPublish: 'Whether Published',
      reviewConfirmStatus: 'Review Confirm Status',
      reviewConfirmUser: 'Review Confirm User',
      submitTip: 'Data cannot be modified after submitting, confrim to submit?',
      balance: {
        templateTitle: 'Select Month',
        pageTitle: 'Balance Sheet',
        accountCategory: 'Account Categories',
        firstLevelAccount: '1st Level Account',
        secondLevelAccount: '2nd Level Account',
        thirdLevelAccount: '3rd Level Account',
        openinBalance: 'Beginning Balance',
        benchmarkReport: 'Base Report',
        adjustment: 'Adjustment',
        lend: 'Debt',
        loan: 'Credit',
        adjustmentReport: 'Adjusted Report',
        remark: 'Remark'
      },
      incomeStatement: {
        pageTitle: 'Income Statement',
        accountCategory: 'Account Categories',
        firstLevelAccount: '1st Level Account',
        secondLevelAccount: '2nd Level Account',
        thirdLevelAccount: '3rd Level Account',
        fourthLevelAccount: '4th Level Account',
        fifthLevelAccount: '5th Level Account',
        openinBalance: 'Beginning Balance',
        benchmarkReport: 'Base Report',
        adjustment: 'Adjustment',
        lend: 'Debt',
        loan: 'Credit',
        adjustmentReport: 'Adjusted Report',
        remark: 'Remark'
      },
      adjustingEntries: {
        pageTitle: 'Adjustment Entry Summary',
        dealerCode: 'Dealer Code:',
        dealerName: 'Dealer Name:',
        reporter: 'Creator:',
        order: 'Entry No.',
        lendLoan: 'Dr/Cr',
        account: 'Account',
        adjustmentAmount: 'Adjustment Amount',
        adjustmentRemark: 'Adjustment Remark（Explain the reason for entry adjustment. For example, if the account of "special profit and loss adjustment" is used, explain the name of the original balance sheet account replaced）',
        updateBy: 'Operator',
        debtor: 'Debt',
        creditor: 'Credit',
        validateTip: 'Please fill in the debt amount correctly',
        validateTip1: 'Warning:Debt/Credit not Balance（Debt：',
        validateTip2: '，Credit：',
        validateTip3: 'Debt/Credit not Balance, Can Not Submit'
      },
      operatingData: {
        pageTitle: 'Operation Data',
        dealerCode: 'Dealer Code:',
        dealerName: 'Dealer Name:',
        reporter: 'Creator:',
        personnelInformationTable: 'Sheet 2-1 Staff Information',
        vehicleSaleDepPersonInfo: 'Sheet 2-1-1 Sales Department Staff Information',
        period: 'Period:',
        time: 'Time:',
        classifyByDep: 'Classify by Department',
        staffNumber: 'Staff No.',
        incomeStmPayrollAcc: 'Income Statement Payroll Account',
        currentAmount: 'Current Amount',
        perCapitaMonthlySalary: 'Monthly Salary per Capita',
        specialCaseNote: 'Remark（Any special circumstances, please note）',
        afterSalesDepPersonInfo: 'Sheet 2-1-2 Aftersales Department Staff Information',
        adminPersonInfo: 'Sheet 2-1-3 G&A Department Staff Information',
        usedCarBizData: 'Sheet 2-2 Used Car Business Information',
        category: 'Category',
        detail: 'Detail',
        numberTimes: 'Vehicle Amount',
        incomeStmUsedCarGrossPrfAcc: 'Income Statement Used Car GP',
        money: 'Amount',
        singleIncome: 'Income by Transaction',
        reportRemark: 'Filling Instruction',
        afterSalesBizStatistics: 'Sheet 2-3 Aftersales Business Information',
        project: 'Project',
        unit: 'Unit',
        amount: 'Amount',
        remark: 'Description',
        newVehicleSalesRebate: 'Sheet 2-4 VME - Financial Discount',
        locationTable: 'Sheet 2-5 Site Information',
        isSiteOwned: 'Whether Own The Site',
        siteLeaseTable: 'Sheet 2-5-1 Site Lease（No matter whether the rent is paid in the current period or not, it must be filled in as long as there is a lease situation）',
        brand: 'Brand',
        monthlyRent: 'Monthly Rent Expense（Yuan）',
        totalLeaseholdArea: 'Total Rental Space (M2)',
        monthlyRentPerSqMeter: 'Monthly Rent Expense/ Square Metre',
        specialCaseNote2: 'Remark（Special case，Please specify, for example, the lease is free of charge）',
        totalCurrentMonthlyRent: 'Total Accrued Monthly Rental Expense',
        top5ReceiveBalance: 'Sheet 2-6-1 Top 5 Accounts Receivable (including related parties and non related parties)',
        top5TotalReceiveBalance: 'Sum of Top 5 Accounts Receivable Balance',
        serialNumber: 'Number',
        receiveBalanceCoName: 'The Full Name of Account Receivable Company',
        relationship: 'Relationship',
        useOfProceeds: 'Use of Fund',
        top5AdvanceBalance: 'Sheet 2-6-2 Top 5 Prepaid Account (including related parties and non related parties)',
        top5TotalAdvanceBalance: 'Sum of Top 5 Prepaid Accounts Balance',
        advanceBalanceCoName: 'The Full Name of Prepaid Account Company',
        top5OtherReceiveBalance: 'Sheet 2-6-3 Top 5 Other Account Receivable (including related parties and non related parties）',
        top5TotalOtherReceiveBalance: 'Sum of Top 5 Other Accounts Receivable Balance',
        otherReceiveBalanceCoName: 'The Full Name of Other Account Receivable Company',
        top5PayBalance: 'Sheet 2-6-4 Top 5 Account Payable (including related parties and non related parties）',
        top5TotalPayBalance: 'Sum of Top 5 Account Payable Balance',
        PayBalanceCoName: 'The Full Name of Account Payable Company',
        top5AdvanceReceiveBalance: 'Sheet 2-6-5 Top 5 Account Receivable in Advance(including related parties and non related parties）',
        top5TotalAdvanceReceiveBalance: 'Sum of Top 5 Account Receivable in Advance Balance',
        advanceReceiveCoName: 'The Full Name of Account Receivable in Advance Company',
        top5OtherPayBalance: 'Sheet 2-6-6 Top 5 Other Account Payable(including related parties and non related parties）',
        top5TotalOtherPayBalance: 'Sum of Top 5 Other Account Payable Balance',
        otherPayBalanceCoName: 'The Full Name of Other Account Payable Company',
        validateTip1: 'Salary and employee quantity not match, please amend',
        validateTip2: 'Salary and employee quantity not match, please amend ',
        validateTip3: 'Revenue and sales volume not match, please amend',
        validateTip4: 'Revenue and sales volume not match, please amend',
        validateTip5: 'Please fill in amount',
        validateTip6: 'Please fill in company name',
        validateTip7: 'Please fill in the purpose of payment',
        validateTip8: 'The amount shall not be less than 0, please amend',
        validateTip9: 'Please fill in company relationship'
      },
      newVehicleRetail: {
        tableTitle: 'New Vehicle Sales Information',
        vin: 'VIN',
        currenPastSales: 'Current Sales/Previous Sales',
        vehicleName: 'Model',
        purchaseSource: 'Purchase Source',
        allocatingSources: 'Allocation Source',
        salesCategory: 'Sales Type',
        nakedVehicleWithoutTax: 'New Vehicle Sales Revenue（Tax Excluded）',
        newVehicleCost: 'New Vehicle Sales Cost（Tax Excluded）',
        MSRP: 'MSRP（Tax Excluded）',
        grossProfit1: 'GPI',
        VRD: 'VRD（Tax Excluded）',
        VME: 'VME（Tax Excluded）',
        grossProfit2: 'GPII',
        derivativeBusinessInfo: 'New Vehicle Derivative Business Information',
        financialServiceIncome: 'New Vehicle Financial Service Revenue(Tax Excluded)',
        insuranceIncome: 'New Vehicle Insurance Service Revenue(Tax Excluded)',
        stuffSalesIncome: 'New Vehicle Accessories Sales Revenue(Tax Excluded)',
        stuffSalesCost: 'New Vehicle Accessories Sales Cost(Tax Excluded)',
        extendWarrantyIncome: 'New Vehicle Extend Warranty Service Revenue(Tax Excluded)',
        extendWarrantySales: 'New Vehicle Extend Warranty Service Cost(Tax Excluded)',
        otherServiceIncome: 'New Vehicle Other Service Revenue(Tax Excluded)',
        otherServiceCost: 'New Vehicle Other Service Cost(Tax Excluded)',
        includingCashDiscountAmount: 'Including Cash Discount Amount',
        includingFuelGiftCardDiscountAmount: 'Including Fuel Gift Card Discount Amount',
        includingShoppingGiftCardDiscountAmount: 'Including Shopping Gift Card Discount Amount',
        includingMaintenanceServiceDiscountAmount: 'Including Maintenance Service Discount Amount',
        includingOtherDiscountAmount: 'Including Other Discount Amount',
        salesPresent: 'New Vehicle Sales Gifts Cost(Tax Excluded)',
        grossProfit3: 'GPIII',
        formulaArea: 'Formula Area',
        jbOrLh: 'Jag/LR',
        domesticOrImport: 'Import/Domestic'

      },
      newVehicleNotRetail: {
        tableTitle: 'New Vehicle Non-retail Information',
        vin: 'VIN',
        modelName: 'Model',
        beTransferred: 'Transferred Party',
        salesCategory: 'Sales Type',
        incomeWithoutTax: 'Revenue(Tax Excluded)',
        costWithoutTax: 'Cost(Tax Excluded)',
        grossProfit1: 'GPI',
        formulaArea: 'Formula Area',
        vinCheck: 'VIN Check',
        jbOrLh: 'Jag/LR',
        domesticOrImport: 'Import/Domestic',
        repeatCheck: 'Duplicate Check'
      },
      notNewVehicleVme: {
        tableTitle: 'VME Details of Non New Vehicle Sales',
        vin: 'VIN',
        modelName: 'Model',
        salesCategory: 'Sales Type',
        receiptAmount: 'Invoice Amount(Tax Excluded)',
        purchaseCost: 'Purchase Cost(Tax Excluded)',
        MSRP: 'MSRP(Tax Excluded)',
        VME: 'VME(Tax Excluded)',
        vinCheck: 'VIN Check',
        jbOrLh: 'Jag/LR',
        formulaArea: 'Formula Area',
        domesticOrImport: 'Import/Domestic'
      },
      newVehicleRetailProfit: {
        tableTitle: 'New Vehicle Retail GP Data Self Inspection',
        model: 'Model',
        income: 'Revenue',
        cost: 'Cost'
      }
    },
    dealer_document: {
      menuTitle: 'Other Supporting Documents Upload',
      month: 'Month',
      needed: 'Need to Upload',
      actual: 'Actual Upload',
      uploadDownload: 'Upload and Download'
    },
    dealer_anomaly: {
      menuTitle: 'Abnormal Items Explanation',
      fluctuationCheck: 'Variation Check',
      thresholdCheck: 'Threshold Check',
      logicalCheck: 'Logical Check',
      anomalyIndicatorSummary: 'Abnormal Items Summary',
      dealerReport: 'Dealer Submit',
      indicatorName: 'KPI Name',
      compareIndicatorName: 'Compare KPI Name',
      riskLevel: 'Risk Level',
      kpiFormula: 'KPI Formula',
      lastData: 'Last Period Data',
      currentData: 'Current Data',
      fluctuation: 'Variation',
      fluctuationRate: 'Variation%',
      dealerExplain: 'Dealer Explanation',
      evidenceDocs: 'Supplementary Document',
      upperThreshold: 'Threshold Upper Limit',
      lowerThreshold: 'Threshold Lower Limit',
      anomalyReason: 'Abnormal Reason',
      addDocs: 'Add File',
      anomalyExplain: 'Abnormal Items Explanation',
      dataModifyAjustEntry: 'Data Adjustment/Adjustment Entry'
    },
    dealer_report: {
      menuTitle: 'Dealer Report Download',
      name: 'Name',
      date: 'Date',
      month: 'Month'
    },
    dealer_reviewReport: {
      menuTitle: 'Audited Report Download',
      name: 'Name',
      date: 'Date',
      month: 'Month'
    },
    dealer_entryConfirm: {
      month: 'Month',
      dr: 'Dr/Cr',
      accountName: 'Account Name',
      money: 'Amount',
      entryConfirm: 'Adjustment Entry Confirm',
      updateBy: 'Update By'
    },
    dealer_reviewConfirm: {
      menuTitle: 'Confirmation Letter',
      confirm: 'Confirm',
      appeal: 'Appeal',
      reviewConfirm: 'Confirm',
      confirmBy: 'Confirm By',
      appealContent: 'Appeal Content',
      uploadFiles: 'Upload File',
      ifConfirm: 'Whether Confirm'
    },
    dealer_invoice: {
      menuTitle: 'Invoice Template',
      type: 'Invoice Type',
      dealerCode: 'Dealer Code',
      dealerName: 'Seller',
      period: 'Period',
      invoiceDate: 'Invoice Date',
      invoiceCode: 'Invoice Code',
      invoiceNo: 'Invoice No.',
      purchaseCo: 'Purchaser',
      vin: 'VIN',
      income: 'Amount（Tax Excluded） ',
      taxRate: 'Tax Rate',
      tax: 'Tax',
      incomeTax: 'Amount （Tax Included）',
      invoiceStatus: 'Invoice Status',
      invoiceConfirmType: 'Invoice Confirm',
      reason: 'Do Not Confirm Explanation',
      operationRole: 'Role'
    }

  }
}
export default dealer
