import Vue from 'vue'
import serverUrl from '../config/base-url'
let utils = {}

utils.fileSaveAs = (url, params) => {
  let paramsStr = ''
  for (let key in params) {
    if (typeof params[key] === 'object') {
      // 对象类型，需要判断是否有id，并拼接成对应的参数格式
      if (params[key].id) {
        paramsStr = paramsStr + `&${key}.id=${params[key].id}`
      }
    } else {
      paramsStr = paramsStr + `&${key}=${params[key]}`
    }
  }

  if (paramsStr.length > 0) {
    paramsStr = `?${paramsStr.substring(1)}&access_token=${localStorage.appToken}`
  } else {
    paramsStr = `?access_token=${localStorage.appToken}`
  }

  let a = document.createElement('a')
  a.href = `${serverUrl}${url}${paramsStr}`
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

// 解析查询对象及条件配置
utils.parseQueryCondition = (queryModel, queryConfig = []) => {
  const filter = []

  for (const queryKey in queryModel) {
    // right 值
    const queryModelItem = queryModel[queryKey]
    // 查询条件配置
    const queryConfigItem = queryConfig.find(item => item.prop === queryKey) || {}

    /**
     * 组件配置中包含 operate 属性
     */
    let operate = 'like'

    switch (queryConfigItem.type) {
      // 默认为 =
      case 'Select':
      case 'Datepicker':
        operate = queryConfigItem.operate || '='
        break
      // case 'Input':
      //   break
      default:
        break
    }

    if (typeof queryModelItem === 'string' && queryModelItem.length > 0) { filter.push({ left: queryKey, operate, right: queryModelItem }) }
  }

  return { filter }
}

utils.parseCompName = (compName, compPrefix = 'el-') => {
  let compNameStr = ''
  if (compName.startsWith(compPrefix)) {
    compNameStr = compName.replace(compPrefix, '').replace(/-/ig, '')
  } else {
    compNameStr = compName.replace('sk-', '').replace(/-/ig, '')
  }
  return compNameStr.toUpperCase()
}

/** 千分位格式化 */
utils.thousandFormat = val => {
  if (!val) return
  let str = val + ''
  return str.replace(/\d{1,3}(?=(\d{3})+(\.\d*)+$)/g, '$&,')
}

/** 保留小数位 */
utils.decimalFormat = (val, num) => {
  let str = val + ''
  let rs = parseFloat(str).toFixed(num)
  return parseFloat(rs) !== 0 ? rs : '0'
}

/** 数字格式化，含千分位，保留小数位等，返回字符串 */
utils.numberFormat = (val, params) => {
  let rs = val

  // 没有值时或值为 0 时是否统一显示为 0
  if (!val) {
    return params.showZero ? (params.percent ? '0%' : '0') : ''
  }

  // 百分比
  if (params.percent) {
    rs = utils.accMul(parseFloat(val), 100)
  }
  let num = 2 // default value
  if (params.toFixed) {
    num = (typeof params.toFixed === 'number' ? params.toFixed : num)
    rs = utils.decimalFormat(rs, num)
  }
  if (params.thousand) {
    rs = utils.thousandFormat(rs)
  }
  if (params.percent) {
    rs = rs + '%'
  }
  return rs
}

/**
 * 加法
 * @param arg1
 * @param arg2
 * @returns
 */
utils.accAdd = (arg1 = 0, arg2 = 0) => {
  let r1
  let r2
  let m
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  return (arg1 * m + arg2 * m) / m
}

/**
 * 减法
 * @param arg1
 * @param arg2
 * @returns
 */
utils.accSubtr = (arg1 = 0, arg2 = 0) => {
  var r1, r2, m, n
  try { r1 = arg1.toString().split('.')[1].length } catch (e) { r1 = 0 }
  try { r2 = arg2.toString().split('.')[1].length } catch (e) { r2 = 0 }
  m = Math.pow(10, Math.max(r1, r2))
  // 动态控制精度长度
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m - arg2 * m) / m).toFixed(n)
}

/***
 * 乘法，获取精确乘法的结果值
 * @param arg1
 * @param arg2
 * @returns
 */
utils.accMul = (arg1 = 0, arg2 = 0) => {
  let m = 0
  let s1 = arg1.toString()
  let s2 = arg2.toString()
  try { m += s1.split('.')[1].length } catch (e) {}
  try { m += s2.split('.')[1].length } catch (e) {}
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

/***
 * 除法，获取精确乘法的结果值
 * @param a
 * @param b
 * @returns
 */
utils.accDiv = (a = 0, b = 0) => {
  if (typeof a === 'undefined' || typeof b === 'undefined' || a == null || b == null) { return NaN }
  let c = 1
  let d = 1
  let e = 0
  let f = 0
  try { e = a.toString().split('.')[1].length } catch (g) {}
  try { f = b.toString().split('.')[1].length } catch (g) {}
  c = Number(a.toString().replace('.', ''))
  d = Number(b.toString().replace('.', ''))
  return utils.accMul(c / d, Math.pow(10, f - e))
}

Vue.use({
  install (Vue) {
    // 采用这种方式定义属性，不会被修改和删除
    Object.defineProperty(Vue.prototype, '$utils', { value: utils })
  }
})
