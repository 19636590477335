<template>
  <keep-alive>
    <component :is="currentView" />
  </keep-alive>
</template>
<script>
import MainView from '@/views/MainView'
import { mapMutations } from 'vuex'

const BASEURL = {
  initData: '/system/initData'
}
export default {
  name: 'Ready',
  data () {
    return {
      currentView: null
    }
  },
  created () {
    this.initializeSystem()
  },
  methods: {
    ...mapMutations(['initializeMenu', 'initializeDictionary', 'setUserInfo', 'initializeArea', 'INIT_WEBSOCKET']),
    /**
     * 初始化系统数据
     * 1. 字典
     * 2. 菜单(权限)
     * 3. 登录用户信息
     */
    initializeSystem () {
      const loadingFlag = this.$loading({ fullscreen: true, text: this.$t('tip.systemLoading') })
      this.$axios
        .post(BASEURL.initData)
        .then(resp => {
          const respData = resp.data
          this.initializeMenu(respData.menu)
          this.initializeDictionary(respData.dict)
          this.initializeArea(respData.area)
          this.setUserInfo(respData.user)

          // 连接 websocket
          this.INIT_WEBSOCKET({ userid: respData.user.id, vue: this })

          this.currentView = MainView
        })
        .finally(() => {
          loadingFlag.close()
        })
    }
  }
}
</script>
