<template>
  <el-select
    v-model="value"
    style="width:100%;"
    v-bind="componentProp"
    :filterable="searchable"
    :remote="searchable"
    placeholder
    v-on="componentProp.event || cellEditorParams.event"
  >
    <i
      v-if="searchable"
      slot="prefix"
      class="el-icon-search"
    />
    <el-option
      v-for="item in (componentProp.optionList || [])"
      :key="item.key"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data () {
    return { value: null }
  },
  computed: {
    parent () {
      return this.params.context.componentParent
    },
    newColDef () {
      return this.parent.findColDef(this.params.colDef, this.parent.columnDefs)
    },
    cellEditorParams () {
      let cellEditorParams = {}
      if (typeof this.newColDef.cellEditorParams === 'function') {
        cellEditorParams = this.newColDef.cellEditorParams(this.params)
      } else {
        cellEditorParams = this.newColDef.cellEditorParams
      }
      return cellEditorParams || {}
    },
    componentProp () {
      return this.cellEditorParams.componentProp || {}
    },
    searchable () {
      return this.cellEditorParams.searchable
    }
  },
  created () {
    this.value = this.params.value
  },
  methods: {
    getValue () {
      return this.value
    }
  }
})
</script>
