const system = {
  cn: {
    system: {
      menuTitle: '系统设置'
    },
    system_password: {
      pageTitle: '修改密码',
      currentPassword: '原密码',
      newPassword: '新密码',
      retypeNewPassword: '确认密码',
      veriCode: '验证码',
      sendVeriCode: '发送验证码',
      currentPasswordRequired: '请输入原密码',
      newPwdRequired: '请输入新密码',
      validateCheck: '至少8个字符（至少一个数字、大写字母、小写字母和特殊字符）',
      retypeNewPasswordTip: '请再次输入新密码',
      notConsistent: '两次输入密码不一致',
      regain: '重新发送'
    },
    system_user: {
      menuTitle: '系统用户',
      pageTitle: '系统用户',
      detailDialog: '用户详细',
      loginName: '用户帐号',
      name: '用户姓名',
      companyName: '所属公司',
      password: '密码',
      roleList: '角色列表',
      deptName: '所属部门',
      phone: '手机号码',
      email: '电子邮箱',
      roleIdList: '角色信息',
      status: '状态',
      remarks: '备注',
      unlock: '解锁',
      reset: '重置密码',
      unlockSuccess: '解锁成功',
      resetSuccess: '重置密码成功'
    },
    system_usergroup: {
      menuTitle: '用户组',
      pageTitle: '用户组',
      detailDialog: '用户组详细',
      name: '名称',
      roleList: '角色列表',
      code: '编码',
      status: '状态',
      remarks: '备注',
      userDetailDialog: '组用户列表',
      sysuserDetailDialog: '系统用户列表'
    },
    system_dictionary: {
      menuTitle: '字典设置',
      pageTitle: '字典设置',
      detailDialog: '字典详细',
      label: '字典内容',
      value: '字典值',
      type: '字典类型',
      sort: '排序',
      description: '备注'
    },
    system_parameter: {
      menuTitle: '参数配置',
      pageTitle: '参数配置',
      detailDialog: '参数配置详细',
      label: '内容',
      description: '描述',
      delFlag: '状态'
    },
    system_role: {
      menuTitle: '角色设置',
      pageTitle: '角色设置',
      detailDialog: '角色详细',
      tabTitle: '资源权限',
      name: '角色名称',
      dataScope: '数据范围',
      status: '状态',
      remarks: '备注',
      ruleKey: '权限规则key',
      tableName: '表名',
      tableAlias: '表别名',
      columnName: '列明',
      operation: '操作',
      columnValue: '值',
      datascopeTabTitle: '数据权限',
      attrInUser: '在user中的属性',
      role: '角色'
    },
    system_office: {
      menuTitle: '系统单位',
      groupDetail: '集团详细',
      agencyDetail: '经销商详细',
      name: '名称',
      nameEn: '名称（英文）',
      region: '区域',
      code: '代码',
      sort: '排序',
      level: '级别',
      category: '类型',
      shopDate: '开业时间',
      businessDate: '营业时间',
      address: '联系地址',
      addressEn: '联系地址（英文）',
      phone: '联系电话',
      email: '邮箱',
      area: '省市',
      agencyGroup: '分组',
      master: '总经理',
      dealerType: '类型',
      mainStoreCode: '主店代码',
      areaEn: '省市（英文）',
      cityTier: '城市级别',
      facilityScale: '设施规模',
      salesArea: '销售小区',
      afterSalesArea: '售后小区',
      shortName: '简称',
      group: '集团',
      groupNameEn: '集团（英文）',
      operationSize: '运营规模',
      showroomAddressCn: '展厅',
      showroomAddressEn: '展厅（英文）',
      strategicDealerGroups: '战略经销商集团'
    },
    system_department: {
      menuTitle: '系统部门',
      pageTitle: '系统部门',
      detailDialog: '部门详细',
      name: '部门名称',
      code: '部门代码',
      companyName: '所属机构',
      status: '状态',
      remarks: '备注'
    },
    system_menu: {
      menuTitle: '菜单管理',
      pageTitle: '菜单管理',
      detailDialog: '菜单（操作）详细',
      parentTitle: '父节点名称',
      title: '名称',
      name: '标识',
      permission: '权限',
      sort: '排序',
      isShow: '类型',
      icon: '图标',
      status: '状态'
    },
    system_formdesigner: {
      pageTitle: '表单设计器',
      fddisplaylist: '动态表单列表',
      name: '表单名称',
      publish: '发布',
      tableConfig: '配置',
      phoneDisplay: '移动端展示',
      preview: '预览',
      edit: '修改',
      delete: '删除',
      uploadPlaceholder: '点击或拖拽文件在这里上传'
    },
    system_task: {
      menuTitle: '任务列表',
      detailDialog: '任务列表',
      name: '任务名称',
      fileName: '文件名',
      message: '日志',
      percentage: '进度',
      operationStatus: '操作状态',
      status: '状态',
      operator: '导入人',
      beginUploadTime: '开始时间',
      endUploadTime: '结束时间'
    },
    system_job: {
      menuTitle: '定时任务',
      detailDialog: '定时任务',
      name: '任务名称',
      group: '任务分组',
      className: '执行类',
      cronExpression: '表达式',
      description: '任务描述',
      state: '任务状态',
      execute: '执行任务',
      pause: '暂停任务',
      recover: '恢复任务',
      executeSuccess: '任务执行成功',
      pauseSuccess: '任务暂停成功',
      recoverSuccess: '任务恢复成功'
    }
  },

  en: {
    system: {
      menuTitle: 'System Setup'
    },
    system_password: {
      pageTitle: 'Change Password',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      retypeNewPassword: 'Retype New Password',
      veriCode: 'Verification Code',
      sendVeriCode: 'Send Verification Code',
      newPwdRequired: 'New password required',
      currentPasswordRequired: 'Current password required',
      validateCheck: 'At least 8 characters, including a number, an uppercase letter, a lowercase letter and a special character',
      retypeNewPasswordTip: 'Please retype new password for confirmation',
      notConsistent: 'New Password and confirm password are not consistent!',
      regain: 'Regain'
    },
    system_user: {
      menuTitle: 'System User',
      pageTitle: 'System User',
      detailDialog: 'User Detail',
      loginName: 'User Account',
      name: 'User Name',
      companyName: 'Company Name',
      password: 'Password',
      roleList: 'Role List',
      deptName: 'Department Name',
      phone: 'Phone',
      email: 'Email',
      roleIdList: 'Role List',
      status: 'Status',
      remarks: 'Remarks',
      unlock: 'Unlock',
      reset: 'Reset Password',
      unlockSuccess: 'Unlock Success',
      resetSuccess: 'Reset Success'
    },
    system_usergroup: {
      menuTitle: 'User Group',
      pageTitle: 'User Group',
      detailDialog: 'User Group Detail',
      name: 'Name',
      roleList: 'Role List',
      code: 'Code',
      status: 'Status',
      remarks: 'Remarks',
      userDetailDialog: 'User List',
      sysuserDetailDialog: 'System User List'
    },
    system_dictionary: {
      menuTitle: 'Dictionary Setting',
      pageTitle: 'Dictionary Setting',
      detailDialog: 'Dictionary Detail',
      label: 'Dictionary Content',
      value: 'Dictionary Value',
      type: 'Dictionary Type',
      sort: 'Sort',
      description: 'Remarks'
    },
    system_parameter: {
      menuTitle: 'Parameter Configuration',
      pageTitle: 'Parameter Configuration',
      detailDialog: 'Parameter Details',
      label: 'Content',
      description: 'Description',
      delFlag: 'Status'
    },
    system_role: {
      menuTitle: 'Character Setting',
      pageTitle: 'Character Setting',
      detailDialog: 'Character Detail',
      tabTitle: 'Resource Authority',
      name: 'Character Name',
      dataScope: 'Data Scope',
      status: 'Status',
      remarks: 'Remarks',
      ruleKey: 'Rule Key',
      tableName: 'Table Name',
      tableAlias: 'Table Alias',
      columnName: 'Column Name',
      operation: 'Operation',
      columnValue: 'Value',
      datascopeTabTitle: 'Data Authority',
      attrInUser: 'User Arribute',
      role: 'Role'
    },
    system_office: {
      menuTitle: 'Dealer Master List',
      groupDetail: 'Group Detail',
      agencyDetail: 'Dealer Detail',
      name: 'Name',
      region: 'Region',
      code: 'Code',
      sort: 'Sort',
      level: 'Level',
      category: 'Category',
      shopDate: 'Opening Date',
      businessDate: 'Dealer Age',
      address: 'Address',
      phone: 'Phone',
      email: 'Email',
      area: 'Province',
      agencyGroup: 'Group',
      master: 'Fin Manager'
    },
    system_department: {
      menuTitle: 'System Department',
      pageTitle: 'System Department',
      detailDialog: 'Department Detail',
      name: 'Department Name',
      code: 'Department Code',
      companyName: 'Dealer Name',
      status: 'Status',
      remarks: 'Remarks'
    },
    system_menu: {
      menuTitle: 'Menu Management',
      pageTitle: 'Menu Management',
      detailDialog: 'Menu Details',
      parentTitle: 'Parent Node Title',
      title: 'Title',
      name: 'Name',
      permission: 'Authority',
      sort: 'Sort',
      isShow: 'Categories',
      icon: 'Icon',
      status: 'Status'
    },
    system_formdesigner: {
      pageTitle: 'Form Designer',
      fddisplaylist: 'Dynamic Form list',
      name: 'Form Name',
      publish: 'Publish',
      tableConfig: 'Configuration',
      phoneDisplay: 'Mobile Display',
      preview: 'Preview',
      edit: 'Edit',
      delete: 'Delete',
      uploadPlaceholder: 'Click or drag files here to upload'
    },
    system_task: {
      menuTitle: 'Task List',
      detailDialog: 'Task List',
      name: 'Task Name',
      fileName: 'File Name',
      message: 'Log',
      percentage: 'Progress',
      operationStatus: 'Operation Status',
      status: 'Status',
      operator: 'Operator',
      beginUploadTime: 'Start Time',
      endUploadTime: 'End Time'
    },
    system_job: {
      menuTitle: 'Cron Job',
      detailDialog: 'Cron Job',
      name: 'Job Name',
      group: 'Job Group',
      className: 'Execution Class',
      cronExpression: 'Expression',
      description: 'Description',
      state: 'Status',
      execute: 'Execute',
      pause: 'Pause',
      recover: 'Recover',
      executeSuccess: 'Execute Success',
      pauseSuccess: 'Pause Success',
      recoverSuccess: 'Recover Success'
    }
  }
}
export default system
