const flexibleReport = {
  cn: {
    flexibleReport: {
      menuTitle: '灵活报告'
    },
    flexibleReport_flexibleReport: {
      menuTitle: '灵活报告'
    }
  },

  en: {
    flexibleReport: {
      menuTitle: 'Flexible Report'
    },
    flexibleReport_flexibleReport: {
      menuTitle: 'Flexible Report'
    }

  }
}
export default flexibleReport
