<template>
  <div
    class="ag-grid__wrapper"
    :style="gridStyle"
  >
    <!-- 查询条件 -->
    <div class="search">
      <slot />
    </div>
    <!-- 表格 -->
    <div class="ag-grid">
      <ag-grid-vue
        ref="agGrid"
        style="{width:100%;height:100%;}"
        :class="className"
        :modules="modules"
        :row-data="rowData"
        :edit-type="editType"
        :pagination="pagination"
        :column-defs="newColDefs"
        :grid-options="gridOptions"
        :pagination-page-size="paginationPageSize"
        :framework-components="frameworkComponents"
        :row-selection="rowSelection"
        :enable-range-selection="true"
        @gridReady="onGridReady"
        @modelUpdated="onModelUpdated"
        @gridSizeChanged="onGridSizeChanged"
        @rowClicked="handleRowClicked"
        @rowSelected="handleRowSelected"
        @cellClicked="handleCellClicked"
        @cellValueChanged="handleCellValueChanged"
      />
    </div>
  </div>
</template>

<script>
import Schema from 'async-validator'
import select from '../component/select'
import datePicker from '../component/datePicker'
// import { AgGridVue } from 'ag-grid-vue'
import { AgGridVue } from '@ag-grid-community/vue'
// import { AllCommunityModules } from '@ag-grid-community/all-modules'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { LicenseManager } from '@ag-grid-enterprise/core'
import CustomTooltip from '../component/tooltip'

console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  const key = `CompanyName=PwC BC (PD) BJ Branch,LicensedGroup=PwC BC (PD) BJ Branch,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=3,AssetReference=AG-007858,ExpiryDate=23_April_2021_[v2]_MTYxOTEzMjQwMDAwMA==05ec5af0dbf84515d62e1863adef4c3d`
  const cleanedLicenseKey = key.replace(/[\u200B-\u200D\uFEFF]/g, '')
  LicenseManager.setLicenseKey(cleanedLicenseKey)
}

export default {
  name: 'AgGrid',
  components: { AgGridVue },
  props: {
    /**
     * 列定义，参照 Ag-Grid Column Definitions
     * cellEditorParams --> 编辑模式的自定义属性放在这里
     * cellEditorParams.componentProp --> 编辑模式的 element ui 自有属性放在这里
     */
    columnDefs: { type: Array },

    /** 常用属性 */
    editType: { type: String },
    rowData: { type: Array, required: true },
    rowSelection: { type: String },
    className: { type: String, default: 'ag-theme-balham' },
    pagination: { type: Boolean, default: false },
    paginationPageSize: { type: Number },

    /** 注册框架组件 */
    frameworkComponents: { type: Object, default: () => { return {} } },

    /** 所有表格属性或事件都可以在这里定义 */
    gridOptions: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      gridStyle: null,
      newColDefs: [],
      modules: AllModules
    }
  },
  mounted () {
    this.gridStyle = this.$el.style.cssText
  },
  beforeMount () {
    // 在子组件中代理父组件
    this.gridOptions.context = { componentParent: this }
    // 默认为自适应分页
    if (this.gridOptions.paginationAutoPageSize === undefined) {
      this.gridOptions.paginationAutoPageSize = true
    }
    // 默认不开启列虚拟渲染，避免校验单元格定位问题
    if (this.gridOptions.suppressColumnVirtualisation === undefined) {
      this.gridOptions.suppressColumnVirtualisation = true
    }
    // 自定义 column type
    this.gridOptions.columnTypes = {
      Select: { cellEditor: 'Select' },
      DatePicker: { cellEditor: 'DatePicker' },
      NumberInput: { valueParser: params => Number(params.newValue) }
    }
    // 设置表格默认属性
    this.gridOptions.defaultColDef = Object.assign(this.gridOptions.defaultColDef || {}, {
      filter: true,
      sortable: true,
      resizable: true,
      cacheQuickFilter: true,
      tooltipShowDelay: 200,
      tooltipComponent: 'customTooltip',
      tooltipValueGetter: params => {
        return params.colDef.valueFormatter ? params.colDef.valueFormatter(params) : params.value
      }
    })
    this.frameworkComponents.DatePicker = datePicker
    this.frameworkComponents.Select = select
    this.frameworkComponents.customTooltip = CustomTooltip
    // columnDefs 变更引起列表重绘，导致 Select 查询时列表不断刷新，暂时赋值给 newColDefs，columnDefs 动态取值用
    this.newColDefs = this.columnDefs
  },
  methods: {
    /**
     * 手动重绘列表
     * columnDefs 如有更改，需手动重绘
     * @param {Array} columnDefs
     */
    redrawGrid (columnDefs) {
      this.newColDef = columnDefs
      this.gridOptions.api.setColumnDefs(this.newColDef)
      // TODO: 监听列宽、排序等行为，避免重绘时一些属性恢复默认值
      // https://www.ag-grid.com/javascript-grid-column-definitions/
    },
    onGridReady (params) {
      this.$emit('gridReady', params)
    },
    onModelUpdated (params) {
      params.api.sizeColumnsToFit() // 列宽自适应
      this.$emit('modelUpdated', params)
    },
    onGridSizeChanged (params) {
      params.api.sizeColumnsToFit() // 列宽自适应
      this.$emit('gridSizeChanged', params)
    },
    handleCellClicked (params) {
      this.$emit('cellClicked', params)
    },
    handleCellValueChanged (params) {
      this.$emit('cellValueChanged', params)
    },
    handleRowClicked (params) {
      this.$emit('rowClicked', params)
    },
    handleRowSelected (params) {
      this.$emit('rowSelected', params)
    },
    /**
     * 表格校验
     * @param {Object} rowNode 目标行
     * @param {Function} callback 校验后的回调
     */
    validate (rowNode, callback) {
      this.gridOptions.api.stopEditing()
      this.gridOptions.columnApi.resetColumnState()

      let flag = true
      const rowData = rowNode.data
      const rowIndex = rowNode.rowIndex
      const displayedColumns = this.gridOptions.api.columnController.allDisplayedColumns

      /** 逐格校验 */
      const validateField = (columnDefs) => {
        let field
        let colDef
        let cellEditorParams

        for (const i in columnDefs) {
          if (!flag) return

          colDef = columnDefs[i].colDef
          field = colDef.field
          if (typeof colDef.cellEditorParams === 'function') {
            cellEditorParams = colDef.cellEditorParams(rowNode)
          } else {
            cellEditorParams = colDef.cellEditorParams
          }

          let editable = false
          if (colDef.editable) {
            editable = (typeof colDef.editable === 'function') ? colDef.editable(rowNode) : colDef.editable
          }

          if (editable && cellEditorParams) {
            if (cellEditorParams.rules) {
              const descriptor = { [field]: cellEditorParams.rules }
              const validator = new Schema(descriptor)
              // https://www.npmjs.com/package/async-validator/v/1.8.5
              validator.validate({ [field]: rowData[field] }, (errors, fields) => {
                if (errors) {
                  flag = false
                  this.focusOnCell(rowIndex, columnDefs[i].colId)
                  this.$message({ type: 'warning', message: errors[0].message })
                }
              })
            }
          }
        }
      }

      validateField(displayedColumns)
      callback(flag)
    },
    validateAll () {
      return new Promise((resolve, reject) => {
        let result = true
        if (this.rowData.length) {
          this.rowData.forEach((row) => {
            if (result) {
              this.validate({ data: row }, (flag) => {
                result = flag
              })
            }
          })
        }
        if (result) {
          resolve(true)
        } else {
          reject(new Error())
        }
      })
    },
    /** 查找 coldef */
    findColDef (colDef, columnDefs) {
      let key = 'field'
      for (const i in columnDefs) {
        if (!colDef.colId && !colDef.field) {
          throw new Error('Property field or colId is required in column definitions')
        }
        if (colDef.colId) key = 'colId'
        if (columnDefs[i][key] === colDef[key]) {
          return columnDefs[i]
        } else if (columnDefs[i].children) {
          return this.findColDef(colDef, columnDefs[i].children)
        }
      }
    },
    focusOnCell (rowIndex, colId) {
      this.gridOptions.api.setFocusedCell(rowIndex, colId)
      this.gridOptions.api.startEditingCell({ rowIndex: rowIndex, colKey: colId })
    }
  }
}
</script>

<style lang="less" scoped>
  .ag-grid__wrapper {
    display: flex;
    flex-direction: column;
    .search {
      /deep/ .el-form-item {
        margin-bottom: 10px;
      }
    }
    .ag-grid {
      flex: 1;
    }
  }
</style>
