import enLocale from 'element-ui/lib/locale/lang/en'

const requireI18n = require.context('@/views/', true, /i18n\.js$/)

const i18nModule = {}
requireI18n.keys().forEach(filename => {
  Object.assign(i18nModule, requireI18n(filename).default.en || {})
})

const en = {
  message: {
    hello: 'hello, world'
  },
  home: {
    pageTitle: 'Home'
  },
  placeholder: {
    username: 'Enter Username Please',
    password: 'Enter Password Please'
  },
  validate: {
    notBlank: 'Please Enter',
    notSelect: 'Please Select',
    notZero: 'Not 0',
    notLessThanZero: 'Not Less Than 0',
    notNumber: 'Enter Number Please',
    notInInterval: 'Not In Interval',
    notValid: 'Invalid Format',
    isRequired: 'Fill In The Compulsory Items Please',
    length: 'The length of [{field}] should be {len}'
  },
  page: {
    err404: '404 Not Found'
  },
  dashboard: {},
  field: {
    operation: 'Operation',
    remarks: 'Remarks',
    status: 'Status'
  },
  operation: {
    success: 'Success',
    error: 'Error',
    search: 'Search',
    create: 'Create',
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    save: 'Save',
    clear: 'Clear All',

    submit: 'Submit',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',

    approve: 'Approve',
    reject: 'Reject',
    copy: 'Copy',
    complete: 'Complete',
    handle: 'Process',
    download: 'Download',
    addChild: 'Add Child Nod',
    export: 'Export',
    batchExport: 'Batch Export',
    import: 'Import',
    atchImport: 'Batch Import',
    upload: 'Upload',
    uploadFile: 'Upload File',
    logout: 'Logout',
    setting: 'Setting',
    preview: 'Preview',
    publish: 'Publish',
    generate: 'Generate',
    back: 'Rollback',
    changePassword: 'Change Password',
    templateDownload: 'Template Download'
  },
  tip: {
    success: 'Success',
    warning: 'Warning',
    systemLoading: 'Loading',
    process: 'Processing...',
    deleteSuccess: 'Delete Success',
    saveSuccess: 'Save Success',
    approveSuccess: 'Submit Success',
    adjustSuccess: 'Adjust Success',
    withdrawSuccess: 'Whithdraw Success',
    copySuccess: 'Copy Success',
    associateSuccess: 'Associate Success',
    collectSuccess: 'Combine Success',
    generateSuccess: 'Generate Success',
    exportSuccess: 'Export Success',
    publishSuccess: 'Publish Success',
    rollbackSuccess: 'Rollback Success',

    confirmDelete: 'Delete Record？',
    confirmApprove: 'Confirm Audit？',
    confirmComplete: 'Confirm Complete？',
    confirmReject: 'Confirm Reject？',
    confirmExport: 'Confirm Export？',

    uploadTip: 'click upload.',
    uploadSuccess: 'Upload Success',

    defaultPassword: 'Default Password 123456',

    websocketSuccess: 'succeeded, please turn to the menu ',
    websocketError: 'failed, please turn to the menu ',
    forDetail: 'for more details',

    fileIsEmpty: 'File is empty',
    fileTypeError: 'File type error',

    taskMenu: 'System Setup -> Task List'
  },
  error: {
    vrd_versionRepeat: 'The same version already exists, please try to create a new version！',
    vme_versionRepeat: 'The same version already exists, please try to create a new version！'
  },
  ...enLocale,
  ...i18nModule
}

export default en
