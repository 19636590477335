<template>
  <div class="sk-table">
    <div class="sk-search">
      <!-- 用于展示查询条件区域，非具名插槽 -->
      <slot />
    </div>
    <el-table
      ref="simpleTableWrapper"
      :data="tableData"
      v-bind="mergeTableAttrs"
      height="auto"
      @select="handleSelect"
      @selection-change="handleSelectionChange"
      @sort-change="handleSortChange"
      @row-click="handleRowClick"
      @row-dblclick="hadleRowDblclick"
    >
      <el-table-column
        v-if="withSelection"
        :reserve-selection="reserveSelection"
        type="selection"
      />
      <simple-table-column
        v-for="(tableColumnItem, tableColumnIndex) in tableColumn"
        :key="tableColumnIndex"
        :column-attrs="tableColumnItem"
        :slot-list="$scopedSlots"
      />
    </el-table>
    <div
      v-if="withPagination"
      class="sk-pagination"
    >
      <el-pagination
        :page-sizes="[10, 25, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataCount"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
      />
    </div>
  </div>
</template>
<script>
import baseTableAttrs from './default-attrs'
import debounce from 'lodash/debounce'
export default {
  name: 'SimpleTable',
  props: {
    tableColumn: { type: Array, required: true },
    tableData: { type: Array, required: true },
    /** 表格自带的属性 Attribute */
    tableAttrs: { type: Object, default: () => { return { } } },
    /** 是否带有分页组件 */
    withPagination: { type: Boolean, default: true },
    /** 是否有 selection 列 */
    withSelection: { type: Boolean, default: false },
    /** selection 列是否有记录功能 */
    reserveSelection: { type: Boolean, default: false },
    /** 总条数 */
    tableDataCount: { type: Number, default: 0 },
    tablePageInfo: { type: Object }
  },
  data () {
    return {
      currentPage: 1,
      pageSize: 10
    }
  },
  computed: {
    page () {
      return { pageNo: this.currentPage, pageSize: this.pageSize }
    },

    mergeTableAttrs () {
      return this.$_.merge(baseTableAttrs, this.tableAttrs)
    }
  },
  watch: {
    page: {
      handler (val) {
        this.handlePageChange(val)
      },
      deep: true
    }
  },
  methods: {
    /** 防止切换页签导致多次请求 */
    handlePageChange: debounce(function (val) {
      this.$emit('page-info-change', Object.assign(this.tablePageInfo, val))
    }),

    handleSelectionChange (selection) {
      this.$emit('selection-change', selection)
    },

    handleSelect (selection, row) {
      this.$emit('select', selection, row)
    },

    handleRowClick (row, column, event) {
      this.$emit('row-click', row, column, event)
    },

    hadleRowDblclick (row, column, event) {
      this.$emit('row-dblclick', row, column, event)
    },

    handleSortChange ({ column, prop, order }) {
      this.$emit('sort-change', { column, prop, order })
    },

    /** 暴露的内部方法 */
    clearSelection () {
      this.$refs.simpleTableWrapper.clearSelection()
    },

    toggleRowSelection (row, status) {
      this.$refs.simpleTableWrapper.toggleRowSelection(row, status)
    }
  }
}
</script>
<style lang="less" scoped>
.sk-table {
  display: flex;
  flex-direction: column;
  height: 100%;

  .sk-search {
    /deep/ .el-form-item {
      margin-bottom: 10px;
    }
  }

  .el-table {
    flex: 1;
  }

  .sk-pagination {
    margin-top: 10px;
    text-align: right;
  }
}
</style>
